import { HttpClient } from '@angular/common/http';
import { Compiler, Inject, Injectable, Injector } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ArticleSectionsService } from '@app/core/services/article-sections.service';
import { ArticlesService } from '@app/core/services/articles.service';
import { CslService } from '@app/layout/pages/library/lib-service/csl.service';
import { EditorsRefsManagerService } from '@app/layout/pages/library/lib-service/editors-refs-manager.service';
import { ReferencePluginService } from '@app/layout/pages/library/lib-service/reference-plugin.service';
import { RefsApiService } from '@app/layout/pages/library/lib-service/refs-api.service';
import { uuidv4 } from 'lib0/random';
import { ChooseManuscriptDialogComponent } from '../dialogs/choose-manuscript-dialog/choose-manuscript-dialog.component';
import { TreeService } from '../meta-data-tree/tree-service/tree.service';
import {  renderSectionFunc } from '../utils/articleBasicStructure';
import { CommentsService } from '../utils/commentsService/comments.service';
import { DetectFocusService } from '../utils/detectFocusPlugin/detect-focus.service';
import { articleSection } from '../utils/interfaces/articleSection';
import { TrackChangesService } from '../utils/trachChangesService/track-changes.service';
import { YjsHistoryService } from '../utils/yjs-history.service';
import { FormBuilderService } from './form-builder.service';
import { MenuService } from './menu.service';
import { PmDialogSessionService } from './pm-dialog-session.service';
import { ProsemirrorEditorsService } from './prosemirror-editors.service';
import { YdocService } from './ydoc.service';
import { AuthService } from '@app/core/services/auth.service'
import { EnforcerService } from '@app/casbin/services/enforcer.service';
import { CasbinGlobalObjectsService } from '@app/casbin/services/casbin-global-objects.service';
import { NotificationsService } from '@app/layout/widgets/arpha-navigation/notifications/notifications.service';
import { CitableElementsService, IOlderVersionCitableElements } from './citable-elements.service';
import {MatSnackBar, MatSnackBarRef} from '@angular/material/snack-bar';
import { JatsErrorsDialogComponent } from '../dialogs/jats-errors-dialog/jats-errors-dialog.component';
import { ChangesSectionComponent } from '../changes-section/changes-section.component';
import { CollaboratorsService } from '../dialogs/add-contributors-dialog/collaborators.service';
import { TaxonService } from '../taxons/taxon.service';
import { CitationButtonsService } from '../utils/citation-buttons/citation-buttons.service';
import { catchError } from 'rxjs/operators';
import { of, Subject, Subscription } from 'rxjs';
import { createDemoTemplate } from '../utils/serverErrorWorkAround';
import { AppConfig, APP_CONFIG } from '@app/core/services/app-config';
import { SpellcheckService } from '../utils/spellcheck-plugin/spellcheck.service';
import { pageDimensionsInPT } from '../dialogs/edit-before-export/edit-before-export.component';
import { UntypedFormControl } from '@angular/forms';
import { LinkButtonsService } from '../utils/link-buttons/link-buttons.service';
import { DataPaperService } from '../dialogs/export-options/jatsXML/data-paper.service';


@Injectable({
  providedIn: 'root'
})
export class ServiceShare {

  articleLayouts:any

  TaxonService?:TaxonService
  CollaboratorsService?:CollaboratorsService
  ChangesSectionComponent?:ChangesSectionComponent
  CitableElementsService?:CitableElementsService
  citationButtonsService: CitationButtonsService
  ReferencePluginService?:ReferencePluginService
  CslService?:CslService
  ProsemirrorEditorsService?:ProsemirrorEditorsService
  YdocService?:YdocService
  TreeService?:TreeService
  CommentsService?:CommentsService
  DetectFocusService?:DetectFocusService
  TrackChangesService?:TrackChangesService
  ArticleSectionsService?:ArticleSectionsService
  ArticlesService?:ArticlesService
  YjsHistoryService?:YjsHistoryService
  PmDialogSessionService?:PmDialogSessionService
  MenuService?:MenuService
  EditorsRefsManagerService?:EditorsRefsManagerService
  FormBuilderService?:FormBuilderService
  RefsApiService?:RefsApiService
  AuthService?:AuthService
  EnforcerService?:EnforcerService
  CasbinGlobalObjectsService?:CasbinGlobalObjectsService
  NotificationsService?:NotificationsService
  SpellcheckService: SpellcheckService
  LinkButtonsService: LinkButtonsService
  DataPaperService?: DataPaperService


  constructor(
    public dialog: MatDialog,
    private router: Router,
    public httpClient:HttpClient,
    private _snackBar: MatSnackBar,
    private injector: Injector, 
    private compiler: Compiler,
    @Inject(APP_CONFIG) public config: AppConfig,
    ) {

  }

  userRole: string;

  onlyOldVersions = false;

  canUseTrackChanges = true;
  canEditArticle = true;
  canPreviewArticle = true;
  canAddComments = true;
  canAddTaxons = true;
  canUseVersions = true;
  hasOwnerCommentsPolicy = false;
  
  oldVersion = false;

  titleControl = new UntypedFormControl();
  subscription = new Subscription();

  globalObj:{[key:string]:any} = {}
  dictionaries: {[key: string]: {[key: string]: string}};
  dictionarySubject = new Subject();

  escapeHtmlTags = /<\/?[^>]+(>|$)/g;

  private validationSubject = new Subject<void>();
  validationObservable$ = this.validationSubject.asObservable();
  
  private validationResultsSubject = new Subject<number>();
  validationResults$ = this.validationResultsSubject.asObservable();

  updateValidationResults(results: number) {
    this.validationResultsSubject.next(results);
  }

  triggerValidation() {
    this.validationSubject.next();
  }

  addDataToGlobalObj(dataKey:string,data:any){
    this.globalObj[dataKey] = data
  }

  compareObjects(obj1: any, obj2: any) {
    return JSON.stringify(obj1) !== JSON.stringify(obj2);
  }

  resolversData:{[key:string]:any} = {}

  addResolverData(resolveKey:string,data:any){
    this.resolversData[resolveKey] = data;
  }

  logData(){

    this.ProsemirrorEditorsService!.transactionCount = 0;
  }

  makeFlat?:()=>void

  resetServicesData (shouldResetProsemirrorEditorsAndDoc: boolean){
    if(shouldResetProsemirrorEditorsAndDoc) {
      this.ProsemirrorEditorsService?.resetProsemirrorEditors();
    }
    this.YdocService?.resetYdoc();
    this.TreeService?.resetTreeData();
    this.CommentsService?.resetCommentsService();
    this.DetectFocusService?.resetDetectFocusService();
    this.TrackChangesService?.resetTrackChangesService();
    this.YjsHistoryService?.resetHistoryData();
    this.resolversData = {}
  }

  updateCitableElementsViews(){
    if(!this.YdocService.doNotRenderEndEditor) {
      this.ProsemirrorEditorsService.editMode = true;
      this.YjsHistoryService.captureBigOperation();
      this.CitableElementsService.updateOnlyElementsViews();
    }
  }

  updateCitableElementsViewsAndCites(olderVersionElements?: IOlderVersionCitableElements){
    this.ProsemirrorEditorsService.editMode = true;
    this.YjsHistoryService.captureBigOperation()
    this.CitableElementsService.updateElementsAndElementsCitations(olderVersionElements);
  }

  shouldOpenNewArticleDialog = false;

  createNewArticle(fromToolBar?:boolean){
    this.ProsemirrorEditorsService.spinSpinner()
    this.ArticleSectionsService!.getAllLayouts().subscribe((articleLayouts: any) => {
      this.articleLayouts = articleLayouts
      const dialogRef = this.dialog.open(ChooseManuscriptDialogComponent, {
        width: '563px',
        // height: '657px',
        panelClass:'choose-namuscript-dialog',
        data: { layouts: articleLayouts }
      });
      this.ProsemirrorEditorsService.stopSpinner()
      dialogRef.afterClosed().subscribe(result => {
        if(!result) return;
        this.ProsemirrorEditorsService.spinSpinner();
        let userData;
        this.AuthService.currentUser$
        // .pipe(mergeMap(data => {
          
        //   return this.ArticleSectionsService.getLayoutById(result);
        // }))
        .subscribe((data: any)=>{
          userData = { data };
          this.ArticlesService!.createArticle('Untitled',+result).pipe(catchError(() => {
            createDemoTemplate.data.uuid = uuidv4();
            return  of(createDemoTemplate)
          })).subscribe((createArticleRes:any)=>{
            this.ArticlesService.getArticleDomainPolicies(createArticleRes.data.uuid).subscribe({
              next: (res) => {
               this.EnforcerService.policiesChangeSubject.next(res);
              },
              error: (err) => {
                console.error(err);
              }
            })
            const selectedLayout = createArticleRes.data.layout.template;
            const articleStructure: articleSection[] = [];
            this.ArticlesService.replayObservable = of(createArticleRes);
            this.resetServicesData(true);
            this.dictionarySubject.next(createArticleRes.data.layout.settings.dictionary);
            this.YdocService!.setArticleData(createArticleRes.data,true)
            this.router.navigate([createArticleRes.data.uuid])
            this.YdocService.newArticleIsCreated(userData,createArticleRes.data.uuid)
            selectedLayout.sections = selectedLayout.sections.filter((x: any)=> x.name != 'Figures' && x.name != 'References' && x.name != 'Tables' && x.name != 'SupplementaryMaterials' && x.name != 'Footnotes');
            selectedLayout.sections.forEach((section: any) => {
              if(section.settings&&section.settings.main_section == true){
                renderSectionFunc(section, articleStructure, this.YdocService.ydoc, this, 'end');
              }
            })
            this.YdocService.articleStructureFromBackend = articleStructure;
          })
        })
      });
    })
  }

  openNotifyUserAccessChangeDialog:(oldAccess:string,newAccess:string)=>void
  openNotAddedToEditorDialog:()=>void
  shareSelf(serviceName:string,serviceInstance:any){
    //@ts-ignore
    this[serviceName] = serviceInstance
  }

  openSnackBar(message:string,action:string,obsFnc:()=>void,durationInSec:number){
    let snackBarRef = this._snackBar.open(message, action,{
      duration: durationInSec * 1000,
    });
    snackBarRef.onAction().subscribe(obsFnc);
  }

  openJatsErrorsDialog(errors:any[]){
    this.dialog.open(JatsErrorsDialogComponent,{
      data: {errors},
    })
  }

  updatePreview = (selfRef: any) => (checkDiff:boolean) => {
    let hasEmptyFields = false;
    let differrance = false;
    selfRef.getMappedComponentsForPreviw(selfRef)().forEach((comp: any, i: number) => {
      let { componentType, url, description } = comp.container;
      if (componentType == '' || url == '') {
        hasEmptyFields = true;
      }
      if(!selfRef.figureComponentsInPrevew){
        differrance = true
      }
      else if (selfRef.figureComponentsInPrevew[i]) {
        let { componentTypePrev, urlPrev, descriptionPrev } = selfRef.figureComponentsInPrevew[i].container
        if(componentTypePrev!==componentType||urlPrev!==url||descriptionPrev!==description){
          differrance = true
        }
      }else{
        differrance = true
      }
    })
    let key = 'A4'
    let a4Pixels = [pageDimensionsInPT[key][0], pageDimensionsInPT[key][1]-(pageDimensionsInPT[key][1]*selfRef.bottomOffset)];
    if(differrance||!checkDiff){
      if(!hasEmptyFields){
        selfRef.figureComponentsInPrevew = selfRef.getMappedComponentsForPreviw(selfRef)()

        selfRef.rowOrder = [];
        selfRef.figureComponentsInPrevew.forEach((figure:any,index:number)=>{

          if(index<4){
            selfRef.rowOrder.push(index+1);
          }
        })
        let rows = selfRef.figureComponentsInPrevew.length/selfRef.columnsFormControl.value
        if(rows%1){
          rows = Math.floor((selfRef.figureComponentsInPrevew.length/selfRef.columnsFormControl.value)+1)
        }
        let r = 0;
        let i = 0;
        let iInR = 0;
        selfRef.figureRows = []
        while (i<selfRef.figureComponentsInPrevew.length&&r<rows) {
          if(!selfRef.figureRows[r]){
            selfRef.figureRows[r] = [];
          }
          selfRef.figureRows[r].push(selfRef.figureComponentsInPrevew[i]);
          i++;
          iInR++;
          if(iInR == selfRef.columnsFormControl.value){
            iInR = 0;
            r++;
          }
        }
        selfRef.rowTemplate = []
        for(let i = 0 ; i < selfRef.columnsFormControl.value;i++){
          selfRef.rowTemplate.push(i);
        }
        let rowsN = rows;
        let collsN = selfRef.columnsFormControl.value;

        let maxImgHeight = a4Pixels[1]/rowsN;
        let maxImgWidth = a4Pixels[0]/collsN;
        selfRef.maxImgHeightPers = maxImgHeight*100/a4Pixels[1];
        selfRef.maxImgWidthPers = maxImgWidth*100/a4Pixels[0];

        let calcImgPersentageFromFullA4 = (img:HTMLImageElement,maxImgHeight:number,maxImgWidth:number,a4PixelRec:number[],figComp:any) => {
          if(img.naturalHeight<maxImgHeight&&img.naturalWidth<maxImgWidth){
            let heightPersent = img.naturalHeight/a4PixelRec[1];
            let widthPersent = img.naturalWidth/a4PixelRec[0];
            figComp.container.hpers = heightPersent
            figComp.container.wpers = widthPersent

            figComp.container.h = img.naturalHeight
            figComp.container.w = img.naturalWidth
          }else if(img.naturalHeight/maxImgHeight > img.naturalWidth/maxImgWidth){
            figComp.container.height = maxImgHeight/a4PixelRec[1];

            let scalePers = maxImgHeight/img.naturalHeight;
            figComp.container.h = maxImgHeight
            figComp.container.w = scalePers*img.naturalWidth
          }else if(img.naturalHeight/maxImgHeight < img.naturalWidth/maxImgWidth){
            figComp.container.width = maxImgWidth/a4PixelRec[0];

            let scalePers = maxImgWidth/img.naturalWidth;
            figComp.container.h = scalePers*img.naturalHeight;
            figComp.container.w = maxImgWidth
          }else if(img.naturalHeight/maxImgHeight == img.naturalWidth/maxImgWidth){
            figComp.container.height = maxImgHeight/a4PixelRec[1];
            figComp.container.width = maxImgWidth/a4PixelRec[0];

            figComp.container.h = maxImgHeight
            figComp.container.w = maxImgWidth
          }
          if(figComp.container.h && figComp.container.w && figComp.container.pdfImgOrigin.includes(selfRef.config.CDNService)){
            figComp.container.pdfImgResized = figComp.container.pdfImgOrigin + `/resize/${figComp.container.w}x${figComp.container.h}/`;
            selfRef.urlMapping[figComp.container.pdfImgOrigin] = figComp.container.pdfImgResized;
          }else{
            figComp.container.pdfImgResized = figComp.container.pdfImgOrigin
            selfRef.urlMapping[figComp.container.pdfImgOrigin] = figComp.container.pdfImgResized;
          }
        }

        for(let i = 0;i<selfRef.figureRows.length;i++){
          for(let j = 0;j<selfRef.figureRows[i].length;j++){
            let image = selfRef.figureRows[i][j];
            let newImg = new Image();

            // newImg.addEventListener('load',()=>{
            //   calcImgPersentageFromFullA4(newImg,maxImgHeight,maxImgWidth,a4Pixels,image);
            // })

            calcImgPersentageFromFullA4(newImg,maxImgHeight,maxImgWidth,a4Pixels,image);

            // newImg.src = image.container.pdfImgOrigin;
          }
        }
        selfRef.figureCanvasData = {
          a4Pixels,
          figRows:selfRef.figureRows,
          nOfRows:rowsN,
          nOfColumns:collsN,
          maxImgHeightPers:selfRef.maxImgHeightPers,
          maxImgWidthPers:selfRef.maxImgWidthPers,
        }
        selfRef.displayPreviewComponents = true;
      }
    }
  }


  updateStylesheetForHiddenComments(userIds: string[]) {
    let styleElement = document.getElementById('hidden-comments-style');
    if (!styleElement) {
        styleElement = document.createElement('style');
        styleElement.id = 'hidden-comments-style';
        document.head.appendChild(styleElement);
    }

    let cssRules = '';
    userIds.forEach(userId => {
        cssRules += `.comment[data-userid='${userId}'] { background-color: unset; }\n .user-tooltip[user-id='${userId}'] { display: none !important; } \n .comment[data-userid='${userId}'] .active-comment { background-color: unset !important; }`;
    });
    styleElement.innerHTML = cssRules;
  }

  removeStylesheetForHiddenComments() {
    const styleElement = document.getElementById('hidden-comments-style');
    if (styleElement) {
        document.head.removeChild(styleElement);
    }
  }
}
