import { AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, Inject, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { UntypedFormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { merge, Observable, of, Subject } from 'rxjs';
import { catchError, map, shareReplay, startWith, switchMap } from 'rxjs/operators';

import { ArticlesService } from '@app/core/services/articles.service';
import { ArticleSectionsService } from '@app/core/services/article-sections.service';
import { YdocService } from '../services/ydoc.service';
import { ProsemirrorEditorsService } from '../services/prosemirror-editors.service';
import { ServiceShare } from '../services/service-share.service';
import { EnforcerService } from '@app/casbin/services/enforcer.service';
import { APP_CONFIG, AppConfig } from '@app/core/services/app-config';
import { AllUsersService } from '@app/core/services/all-users.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements AfterViewInit, AfterViewChecked {

  displayedColumns: string[] = ['id', 'title', 'date', 'layout-type', 'autor', 'buttons']; // template-type and lastupdated - column removed
  data: any[] = [];
  realData: any[] = [];


  resultsLength = 0;
  isLoadingResults = true;
  isRateLimitReached = false;
  articleTemplates2: any
  allArticlesData: any;
  searchValue?: string;
  articleLayouts: any;
  typeChange: Subject<any> = new Subject();
  selectedType = -1;
  refreshSubject = new Subject();
  onRender = true;
  filteredAutocompleteTemplates: Observable<any[]>;

  colorFunc = this.ydocService.setUserColor;

  templateTypeControl = new UntypedFormControl("");

  @ViewChild(MatPaginator) paginator?: MatPaginator;
  @ViewChild(MatSort) sort?: MatSort;

  constructor(
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
    private ydocService: YdocService,
    public enforcer: EnforcerService,
    private articlesService: ArticlesService,
    private articleSectionsService: ArticleSectionsService,
    private prosemirrorEditorsService: ProsemirrorEditorsService,
    private serviceShare: ServiceShare,
    private chDetectionRef:ChangeDetectorRef,
    public allUsersService: AllUsersService,
    @Inject(APP_CONFIG) readonly config: AppConfig
  ) {
    this.prosemirrorEditorsService.spinSpinner()

  }

  escapeHtmlTags(articleName: string) {
    return articleName.replace(this.serviceShare.escapeHtmlTags, '');
  }

  ngAfterViewChecked(): void {
    this.chDetectionRef.detectChanges()
  }

  ngAfterViewInit() {
    this.articlesService.replayObservable = null;
    let articlesDataFromResolver = this.route.snapshot.data['product'];

    this.articleSectionsService.getAllLayouts().subscribe((articleLayouts: any) => {
      // this.articleLayouts = [ { name: 'None', id: -1 }, ...articleLayouts.data]
      this.articleLayouts = articleLayouts.data;
    })
    // If the user changes the sort order, reset back to the first page.
    this.sort!.sortChange.subscribe(() => {
      this.paginator!.pageIndex = 0;
    });
    this.serviceShare.resetServicesData(true);
    this.typeChange.subscribe(() => {
      this.paginator!.pageIndex = 0;
    })

    this.filteredAutocompleteTemplates = this.templateTypeControl
      .valueChanges
      .pipe(
        map(value =>
          value.length > 0 ?
          this.articleLayouts.filter(type => type.name.toLowerCase().includes(value.toLowerCase()))
          :
          this.articleLayouts
          )
        )

    if(this.serviceShare.ProsemirrorEditorsService.spinning){
      this.serviceShare.ProsemirrorEditorsService.stopSpinner()
    }
    this.sort?.sort({ disableClear: false, id: 'id', start: 'desc' })

    merge(this.sort!.sortChange, this.paginator!.page, this.typeChange, this.refreshSubject)
      .pipe(
        startWith({}),
        switchMap(() => {
          let params: any = {
            page: (this.paginator?.pageIndex! | 0) + 1,
            pageSize: 7,
          }
          if(this.sort!.active == 'id'){
            //@ts-ignore
            params['sort']=this.sort?._direction=='desc'?'-id':'id'
          }
          if(this.sort!.active == 'date'){
            //@ts-ignore
            params['sort']=this.sort?._direction=='desc'?'-created_at':'created_at'
          }
          if (this.searchValue && this.searchValue != '') {
            params['filter[name]'] = this.searchValue
          }
          if(this.selectedType != -1) {
            params['filter[layout_id]'] = this.selectedType;
          }
          this.isLoadingResults = true;
          if(this.onRender){
            this.onRender = false;
            return of(articlesDataFromResolver)
          }
          return this.articlesService.getAllArticles(params).pipe(catchError(() => new Observable(undefined)))
          //}
        }),
        map((data: any) => {
          this.isLoadingResults = false;
          this.isRateLimitReached = data === null;

          if (data === null) {
            return [];
          }

          return data;
        }),
      )
      .subscribe(data => {
        let dataToDisplay: any = data.data;
        let pag = data.meta.pagination
        let itemsCount = pag.total;
        this.data = dataToDisplay
        this.resultsLength = itemsCount;
        if(this.prosemirrorEditorsService.spinning){
          this.prosemirrorEditorsService.stopSpinner();
        }
      });
      if(this.serviceShare.shouldOpenNewArticleDialog){
        this.openchooseDialog();
        this.serviceShare.shouldOpenNewArticleDialog = false;
      }
  }

  timer: any
  public search(input: HTMLInputElement) {
    if (this.timer) {
      clearTimeout(this.timer);
    }
    this.timer = setTimeout(() => {
      this.searchValue = input.value/* .toLowerCase(); */
      this.typeChange.next('typechange')
      this.timer = undefined
    }, 300)
  }

  filterByType(input: HTMLInputElement, event?) {
    if(input.value.length > 0 && (event.target.className == "mat-option-text" || event.target.tagName == "MAT-OPTION" || event.key == "Enter")) {
      this.selectedType = this.articleLayouts.find(type => type.name == input.value)?.id;
      if(this.selectedType) {
        this.typeChange.next('typechange')
      }
    } else if(event.target.tagName !== "MAT-ICON" && !(event.target.classname && event.target.className.includes("mat-form-field-infix")) && input.value == '' && !event.key){
      (document.getElementsByClassName('width-select')[0] as HTMLElement).style.width = "125px"
    }
  }

  removeTypeInputText(input: HTMLInputElement) {
    this.templateTypeControl.setValue('');

    if(this.selectedType !== -1) {
      this.selectedType = -1;
      this.typeChange.next('typechange');
    }
  }

  focusHandler() {
    (document.getElementsByClassName('width-select')[0] as HTMLElement).style.width = "240px"
  }

  openchooseDialog() {
    this.serviceShare.createNewArticle();
  }

  editArticle(articleData: any) {
    this.serviceShare.resetServicesData(true);
    this.articlesService.replayObservable = this.articlesService.getArticleByUuid(articleData.uuid).pipe(shareReplay(1));
    this.articlesService.replayObservable.subscribe((res: any) => {
      this.ydocService.setArticleData(res.data);
      this.router.navigate([articleData.uuid])
    })
  }

  deleteArticle(deleteArticle: any) {
    this.articlesService.deleteArticleById(deleteArticle.id).subscribe((deleteResponse) => {
      if (deleteResponse.status == 204) {

        this.refreshSubject.next(deleteResponse);
      }
    })
  }

  filterContributors(contributors: any[]) {
    return contributors.filter(c => !this.allUsersService.isHidden(c.role));
  }
}
