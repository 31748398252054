import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { ServiceShare } from '@app/editor/services/service-share.service';
import { createDocFromSnapshot, decodeSnapshot } from 'yjs';
import {changeVersionSubject} from "../../../y-prosemirror-src/plugins/sync-plugin";

export enum VersionChange {
  RETURN_TO_NEWEST = 'returnToNewest',
  VERSION_CHANGE = 'versionChange',
  RERENDER = 'rerender',
  // INIT_ARTICLE_METADATA = "initArticleMetadata"
}

@Component({
  selector: 'app-version',
  templateUrl: './version.component.html',
  styleUrls: ['./version.component.scss'],
})
export class VersionComponent implements AfterViewInit {

  @ViewChild("versionContainer", {read: ElementRef}) versionContainer: ElementRef;

  @Input() version: {date: Date, snapshot: any, index: number, users: any, restored?: Date};
  @Input() versions: {date: Date, snapshot: any, index: number, users: any, restored?: Date}[];
  usernames = [];

  constructor(
    private sharedService: ServiceShare, 
    private router: Router,
    private changeDetector: ChangeDetectorRef
    ) { }

  ngAfterViewInit(): void {    
    if(this.sharedService.YdocService.lastSelectedVersion == this.version.index) {
      this.versionContainer.nativeElement?.classList.add("selected");
      setTimeout(() => {
        (document.querySelector('.all-versions-container') as HTMLElement).scrollTop = this.versionContainer.nativeElement?.offsetTop;
      }, 100);
    } else {
      this.versionContainer.nativeElement?.classList.remove("selected");
    }
    this.changeDetector.detectChanges();
  }

  selectVersion(version: {date: Date, snapshot: any, index: number, users: any}) {
    if(this.sharedService.YdocService.lastSelectedVersion != version.index) {
      (document.querySelector('.version-wrapper .selected') as HTMLElement)?.classList?.remove("selected");
      this.versionContainer.nativeElement?.classList.add("selected");
      this.sharedService.ProsemirrorEditorsService.spinSpinner();
      this.sharedService.YdocService.versionSubject.next("rerender");
      this.router.navigate([`${this.sharedService.YdocService.roomName}`], { fragment: `${version.index}` });
    }
  }

  restoreVersion(event: Event, index: number) {
    event.stopImmediatePropagation();
    this.sharedService.ProsemirrorEditorsService.spinSpinner();
    this.sharedService.YdocService.lastSelectedVersion = index;
    this.sharedService.YdocService.createSnapshot();
    const versionDoc = createDocFromSnapshot(this.sharedService.YdocService.ydoc, decodeSnapshot(this.version.snapshot));
    this.router.navigate([`${this.sharedService.YdocService.roomName}`]);
    changeVersionSubject.next("sync");            
    this.sharedService.YdocService.restoreOldVersionDoc(versionDoc, {snapshot: decodeSnapshot(this.version.snapshot), prevSnapshot: decodeSnapshot(this.version.snapshot)}).subscribe(() => {
      this.sharedService.YdocService.versionSubject.next("reconnect");
      this.sharedService.YdocService.lastSelectedVersion = undefined;
    })
  }
}
