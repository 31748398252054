import { AfterViewInit, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ServiceShare } from '../services/service-share.service';

@Component({
  selector: 'app-editor-container',
  templateUrl: './editor-container.component.html',
  styleUrls: ['./editor-container.component.scss']
})
export class EditorContainerComponent {

  renderEditor = true;

  constructor(
    private serviceShare:ServiceShare,
    private changeDetectorRef: ChangeDetectorRef
  ){
    this.serviceShare.YdocService.versionSubject.subscribe((event: string) => {
      if(event == "reconnect") {
        this.renderEditor = false;
        this.serviceShare.YdocService.shouldReconect = true;          
        setTimeout(() => {
          this.renderEditor = true;
        }, 10);
      } else {
        this.renderEditor = false;
        this.serviceShare.YdocService.shouldReconect = false;          
        setTimeout(() => {
          this.renderEditor = true;
        }, 100);
      }
    })
  }

  togglePermission(permission: string, input?: HTMLInputElement) {
    if(permission == "hidden") {
      const ids = input.value?.split(",") || [];
      this.serviceShare.ProsemirrorEditorsService.userInfo.data["hide_user_from_me"] = ids;
      this.serviceShare.TrackChangesService.changesChangeSubject.next("changes pos calc for all sections");
    } else if (permission == "comments") {
      const ids = input.value?.split(",") || [];
      this.serviceShare.ProsemirrorEditorsService.userInfo.data["hide_user_comments_from_me"] = ids;
      this.serviceShare.CommentsService.commentsChangeSubject.next('comments pos calc for all sections');
      this.serviceShare.ProsemirrorEditorsService.dispatchEmptyTransaction();
    } else {
      this.serviceShare.EnforcerService.policiesFromBackend.forEach((p, i) => {
        if(p.obj == permission) {
          const eft = this.serviceShare.EnforcerService.policiesFromBackend[i].eft == "deny" ? "allow" : "deny";
          this.serviceShare.EnforcerService.policiesFromBackend[i] = { ...this.serviceShare.EnforcerService.policiesFromBackend[i], eft };
        }
      });
      this.serviceShare.EnforcerService.updateAllPolicies(this.serviceShare.EnforcerService.policiesFromBackend);
    }
    this.changeDetectorRef.detectChanges();
  }

}
