import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ChangeDetectorRef } from '@angular/core';
import { Snapshot, Map } from 'yjs';

import { YdocService } from '../services/ydoc.service';
import { basicArticleSection } from '../utils/interfaces/articleSection';
import { ProsemirrorEditorsService } from '../services/prosemirror-editors.service';
import { figure } from '../utils/interfaces/figureComponent';
import { DetectFocusService } from '../utils/detectFocusPlugin/detect-focus.service';
import { ServiceShare } from '../services/service-share.service';
import { RefsApiService } from '@app/layout/pages/library/lib-service/refs-api.service';
import { CslService } from '@app/layout/pages/library/lib-service/csl.service';

@Component({
  selector: 'app-article',
  templateUrl: './article.component.html',
  styleUrls: ['./article.component.scss']
})
export class ArticleComponent implements OnInit {

  articleSectionsStructureFlat: string[];
  articleSectionsStructure: basicArticleSection[];
  articleStructureMap: Map<any>;
  articleFigures?: figure[];
  createdEditorIdsFromOldVersions = [];
  doNotRenderEndEditor = this.ydocService.doNotRenderEndEditor;
  hasFootnotes = this.ydocService.hasFootnotes;
  hasSupplementaryMaterials = this.ydocService.hasSupplementaryMaterials;

  get hasCitableElements () {
    return Object.keys(this.ydocService.referenceCitationsMap.get("refsAddedToArticle")).length > 0 ||
    Object.keys(this.ydocService.figuresMap.get("ArticleFigures")).length > 0 ||
    Object.keys(this.ydocService.tablesMap.get("ArticleTables")).length > 0 || this.versionData
  }

  get hasSupplementaryFiles () {
    return Object.keys(this.ydocService.supplementaryFilesMap.get("supplementaryFiles")).length > 0;
  }

  get hasFootNotes () {
    return Object.keys(this.ydocService.endNotesMap.get("endNotes")).length > 0;
  }

  @Input() versionData: {
    snapshot: Snapshot,
    prevSnapshot: Snapshot,
    userData: any
  };
 
  @ViewChild('headEditor', { read: ElementRef }) headEditor?: ElementRef;

  constructor(
    private ydocService: YdocService,
    private ref: ChangeDetectorRef,
    public prosemirrorEditorsService: ProsemirrorEditorsService,
    public detectFocusService: DetectFocusService,
    public serviceShare: ServiceShare,
    private refsAPI: RefsApiService,
    private cslService: CslService,
  ) {
    this.articleStructureMap = this.ydocService.articleStructureMap;
    this.ydocService.articleStructureMap.observe(() => {
      this.articleSectionsStructure = this.ydocService.articleStructureMap.get('articleSectionsStructure');
      let change = this.serviceShare?.TreeService?.metadatachangeMap!.get('change');
      if (change && (change.action == 'editNode' || change.action == 'saveNewTitle') && this.serviceShare.TreeService!.findNodeById(change.nodeId || change.node.sectionID)?.active) {
        return;
      }
      this.makeFlat();

      setTimeout(() => {
        if (this.detectFocusService.sectionName) {
          if (!this.prosemirrorEditorsService.editorContainers[this.detectFocusService.sectionName]) {
            this.detectFocusService.sectionName = undefined;
          } else {
            let editorView = this.prosemirrorEditorsService.editorContainers[this.detectFocusService.sectionName].editorView
            editorView.focus();
            editorView.dispatch(editorView.state.tr.scrollIntoView());
          }
        }
      }, 50);

    });
    this.articleSectionsStructure = this.ydocService.articleStructureMap.get('articleSectionsStructure');
    this.makeFlat();
    this.articleFigures = this.ydocService.figuresMap?.get('ArticleFigures');
  }

  ngOnInit(): void {
    this.serviceShare.makeFlat = this.makeFlat;
  }

  getSection = (id: string) => this.ydocService.getSectionByID(id);

  makeFlat = () => {
    let articleSectionsStructureFlat: string[] = [];

    let makeFlat = (structure: basicArticleSection[]) => {
      if (structure) {
        structure.forEach((section) => {
          if (section.active) {
            articleSectionsStructureFlat.push(section.sectionID);
          }
          if (section.children.length > 0) {
            makeFlat(section.children);
          }
        })
      }
    }
    makeFlat(this.articleSectionsStructure)
    if(this.ydocService.shouldRestore) {
      this.ydocService.ydoc.getMap("articleStructure").set('articleSectionsStructure', this.articleSectionsStructure);
      this.ydocService.ydoc.getMap("articleStructure").set('articleSectionsStructureFlat', articleSectionsStructureFlat);
      this.ydocService.shouldRestore = false;
    }
    this.articleSectionsStructureFlat = []
    setTimeout(() => {
      if(this.headEditor){
        this.moveHeadEditor = articleSectionsStructureFlat.some(id => {
          return this.ydocService.getSectionByID(id).jats_tag && this.ydocService.getSectionByID(id).jats_tag == 'article-title'
        });
      }
      this.articleSectionsStructureFlat = articleSectionsStructureFlat;
    }, 10)
    return articleSectionsStructureFlat;
  }
  moveHeadEditor = false;

  trackSectionById(index: number, sectionID: string) {
    return sectionID;
  }
}
