import {
  AfterViewChecked,
  AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {MatBottomSheet} from '@angular/material/bottom-sheet';
import {MatDialog} from '@angular/material/dialog';
import {MatDrawer} from '@angular/material/sidenav';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {EnforcerService} from '@app/casbin/services/enforcer.service';
import {ArticleSectionsService} from '@app/core/services/article-sections.service';
import {ArticlesService} from '@app/core/services/articles.service';
import {AuthService} from '@app/core/services/auth.service';
import {EditorsRefsManagerService} from '@app/layout/pages/library/lib-service/editors-refs-manager.service';
import {ReferencePluginService} from '@app/layout/pages/library/lib-service/reference-plugin.service';
import {RefsApiService} from '@app/layout/pages/library/lib-service/refs-api.service';
import {FormioAppConfig} from '@formio/angular';
import {Subject, Subscription, combineLatest} from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';

//@ts-ignore
import * as Y from 'yjs';
import {AddContributorsDialogComponent} from './dialogs/add-contributors-dialog/add-contributors-dialog.component';
import {ExportOptionsComponent} from './dialogs/export-options/export-options.component';
import {TreeService} from './meta-data-tree/tree-service/tree.service';
import {ProsemirrorEditorsService} from './services/prosemirror-editors.service';
import {ServiceShare} from './services/service-share.service';
import {YdocService} from './services/ydoc.service';
import {CommentsService} from './utils/commentsService/comments.service';
import {articleSection, basicArticleSection} from './utils/interfaces/articleSection';
import {TrackChangesService} from './utils/trachChangesService/track-changes.service';
import {CitableElementsService} from './services/citable-elements.service';
import {
  CitableElementsEditButtonsService
} from './utils/citable-elements-edit-buttons/citable-elements-edit-buttons.service';
import {CollaboratorsService} from './dialogs/add-contributors-dialog/collaborators.service';
import {TaxonService} from './taxons/taxon.service';
import { changeVersionSubject } from '../y-prosemirror-src/plugins/sync-plugin.js'
import { ydocData } from './utils/interfaces/ydocData';
import { ImportJatsService } from './dialogs/export-options/jatsXML/importAsJatsXML';
import { JATSImportModalComponent } from './dialogs/export-options/jatsXML/jats-import-modal/jats-import-modal.component';
import { APP_CONFIG, AppConfig } from '@app/core/services/app-config';

@Component({
  selector: 'app-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditorComponent implements OnInit, AfterViewInit, AfterViewChecked, OnDestroy {
  articleSectionsStructure?: articleSection[];

  ydoc?: Y.Doc;
  shouldBuild: boolean = false;
  roomName?: string | null;
  shouldTrackChanges?: boolean;
  active = 'editor';
  articleTemplate: string;
  version: number;
  versionData: {
    snapshot: Y.Snapshot,
    prevSnapshot: Y.Snapshot,
    userData: any
  }
  docIsBuild = false;
  ydocData: ydocData;
  shouldNotDestroy = false;
  restoringDocument = false;

  canEdit = false;
  canSuggest = false;
  canSeeVersions = false;
  canSeeComments = false;
  canSeeContributors = false;
  canSeeSectionsTree = false;

  @ViewChild('trackChangesOnOffBtn', {read: ElementRef})
  trackChangesOnOffBtn?: ElementRef;
  OnOffTrackingChangesShowTrackingSubject: Subject<{
    trackTransactions: boolean;
  }>;

  @ViewChild(MatDrawer) sidebarDrawer?: MatDrawer;
  sidebar = '';

  @ViewChild('metaDataTreeDrawer') metaDataTreeDrawer?: MatDrawer;
  previewMode
  innerWidth: any;
  trackChangesData?: any;
  usersInArticle: any[] = []
  subscription = new Subscription();

  canCreateTag = false;

  get canShowTaxonButtons() {
    return this.serviceShare.TaxonService.canShowTaxonButtons;
  }

  constructor(
    private ydocService: YdocService,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private commentService: CommentsService,
    private _bottomSheet: MatBottomSheet,
    private collaboratorsService: CollaboratorsService,
    private prosemirrorEditorServie: ProsemirrorEditorsService,
    private trackChanges: TrackChangesService,
    private treeService: TreeService,
    public serviceShare: ServiceShare,
    public config: FormioAppConfig,
    public enforcer: EnforcerService,
    private authService: AuthService,
    private editorsRefsManager: EditorsRefsManagerService,
    private articleSectionsService: ArticleSectionsService,
    private articlesService: ArticlesService,
    private citableElementEditButonsServie: CitableElementsEditButtonsService,
    private citableElementsService: CitableElementsService,
    private refsAPI: RefsApiService,
    private changeDetection: ChangeDetectorRef,
    private referencePluginService: ReferencePluginService,
    public taxonService: TaxonService,
    private router: Router,
    private importJatsService: ImportJatsService,
    @Inject(APP_CONFIG) readonly appConfig: AppConfig,
  ) {
    this.serviceShare.titleControl.disable();
    this.subscription.add(this.serviceShare.TaxonService.canTagSelectionSubject.subscribe((canCreateTag) => {
      this.canCreateTag = canCreateTag
    }));
    this.prosemirrorEditorServie.spinSpinner();
    this.previewMode = this.prosemirrorEditorServie.previewArticleMode;

    this.subscription.add(this.prosemirrorEditorServie.usersInArticleStatusSubject.subscribe((status: Map<any, any>) => {
      let userInfo: any[] = [];

      status.forEach((aw, clientId) => {
        if(aw.userInfo && this.ydoc?.clientID && clientId != this.ydoc.clientID) {
          userInfo.push({userInfo: aw.userInfo, clientId});
        }
      })

      this.usersInArticle = userInfo;
      this.ydocService.usersInArticle = userInfo;
    }))

    this.OnOffTrackingChangesShowTrackingSubject =
      prosemirrorEditorServie.OnOffTrackingChangesShowTrackingSubject;

    this.subscription.add(this.serviceShare.TrackChangesService.lastSelectedChangeSubject
      .pipe(debounceTime(200))
      .subscribe((data) => {
      if (!data.changeMarkId || !data.pmDocStartPos || !data.section || this.previewMode.mode) return;
      let {from, to} = this.prosemirrorEditorServie.editorContainers[data.section].editorView.state.selection
      if (from !== to && data.section != this.serviceShare.DetectFocusService.sectionName) return;
      if (!this.sidebarDrawer?.opened) {
        this.sidebarDrawer?.toggle();
      }
      if (this.sidebar != 'changes') {
        this.sidebar = 'changes';

        setTimeout(() => {
          this.trackChanges.changesChangeSubject.next("changes pos calc for all sections");
          setTimeout(() => {
            this.serviceShare.TrackChangesService.lastSelectedChangeSubject.next(data)
          }, 800)
        }, 20);
      }
    }))

    this.subscription.add(this.serviceShare.TaxonService.lastSelectedTaxonMarkSubject
    .pipe(debounceTime(200))
    .subscribe((data) => {
      if (!data.pos || !data.sectionId || !data.taxonMarkId || this.previewMode.mode) return;
      // let {from, to} = this.prosemirrorEditorServie.editorContainers[data.sectionId].editorView.state.selection
      if (/*from !== to &&*/ data.sectionId != this.serviceShare.DetectFocusService.sectionName) return;
      if (!this.sidebarDrawer?.opened) {
        this.sidebarDrawer?.toggle();
      }
      if (this.sidebar != 'taxons') {
        this.sidebar = 'taxons';
        setTimeout(() => {
          this.serviceShare.TaxonService.taxonsMarksObjChangeSubject.next('taxons pos calc for all sections');

          setTimeout(() => {
            this.serviceShare.TaxonService.lastSelectedTaxonMarkSubject.next(data)
          }, 800)
        }, 20);
      }
    }))

    this.subscription.add(this.serviceShare.CommentsService.lastSelectedCommentSubject
    .pipe(debounceTime(200))
    .subscribe((data) => {
      if (!data.commentId || !data.commentMarkId || !data.pos || !data.sectionId) return;
      let {from, to} = this.prosemirrorEditorServie.editorContainers[data.sectionId].editorView.state.selection
      if (/*from !== to &&*/ data.sectionId != this.serviceShare.DetectFocusService.sectionName) return;
      if (!this.sidebarDrawer?.opened) {
        this.sidebarDrawer?.toggle();
      }
      if (this.sidebar != 'comments') {
        this.sidebar = 'comments';
        setTimeout(() => {
          this.commentService.commentsChangeSubject.next('comments pos calc for all sections');
          setTimeout(() => {
            this.serviceShare.CommentsService.lastSelectedCommentSubject.next(data)
          }, 800)
        }, 20);
      }
    }))
    

    this.subscription.add(this.commentService.addCommentSubject.subscribe((data) => {
      if (data.type == 'commentData' && this.sidebar !== 'comments' && data.showBox) {
        if (!this.sidebarDrawer?.opened) {
          this.sidebarDrawer?.toggle();
        }
        this.sidebar = 'comments';
        setTimeout(() => {
          this.commentService.addCommentSubject.next(data);
        }, 200);
      }
    }));

    this.subscription.add(this.taxonService.addTaxonSubject.subscribe((data) => {
      if (data.type == 'taxonData' && this.sidebar !== 'taxons' && data.showTaxonBox) {
        if (!this.sidebarDrawer?.opened) {
          this.sidebarDrawer?.toggle();
        }
        this.sidebar = 'taxons';
        setTimeout(() => {
          this.taxonService.addTaxonSubject.next(data);
        }, 200);
      }
    }));

    let initArtcleStructureMap = () => {
      let hideshowDataInit = this.ydocService.trackChangesMetadata!.get(
        'trackChangesMetadata'
      );
      this.trackChangesData = hideshowDataInit;

      this.ydocService.trackChangesMetadata!.observe((ymap) => {
        let hideshowData = this.ydocService.trackChangesMetadata!.get(
          'trackChangesMetadata'
        );
        if (
          hideshowData.lastUpdateFromUser !==
          this.ydocService.ydoc?.guid
        ) {
        }
        this.shouldTrackChanges = hideshowData.trackTransactions;
        this.trackChangesData = hideshowData;
      });
      /*  this.refsAPI.getReferences().subscribe((refs:any)=>{
         // this.shouldRender = true;
         // this.userReferences = refs.data;
         this.changeDetection.detectChanges();
       }) */
    };
    if (this.ydocService.editorIsBuild) {
      initArtcleStructureMap();
    } else {
      this.subscription.add(this.ydocService.ydocStateObservable.subscribe(({event}) => {
        if (event == 'docIsBuild') {
          initArtcleStructureMap();
        }
      }));
    }
  }

  turnOnOffPreviewMode() {
    if(!this.serviceShare.canEditArticle && !this.serviceShare.canUseTrackChanges) {
      this.prosemirrorEditorServie.previewArticleMode.mode = true;
    } else {
      this.prosemirrorEditorServie.previewArticleMode.mode = !this.prosemirrorEditorServie.previewArticleMode.mode;
    }
  }

  showTreeContainer() {
    this.metaDataTreeDrawer?.toggle();
  }

  ngAfterViewChecked(): void {
    // detect changes only when is needed because of performance
    if(this.prosemirrorEditorServie.editMode || this.ydocService.creatingANewArticle || this.ydocService.editorIsBuild == false) {
      this.changeDetection.detectChanges();
    }
  }

  clickEditorTab() {
    if (this.active == 'library') {
      this.active = 'editor';
    } else {
      this.active = 'editor';
      this.subscription.add(this.refsAPI.getReferences().subscribe((refs: any) => {
        this.changeDetection.detectChanges();
      }));
    }
  }

  getPermissions() {
    return combineLatest([
      this.serviceShare.EnforcerService.enforceAsync("editMode(*)", "edit"), 
      this.serviceShare.EnforcerService.enforceAsync("editMode(*)", "suggest"),
      this.serviceShare.EnforcerService.enforceAsync("versions(*)", "view"),
      this.serviceShare.EnforcerService.enforceAsync("contributors(*)", "view"),
      this.serviceShare.EnforcerService.enforceAsync("sections(*)", "view"),
      // this.serviceShare.EnforcerService.enforceAsync("comments(*, *)", "view"),
    ]);
  }

  userAccess: string;

  ngOnInit(): void {
    const setPermissions = ([editMode, suggestMode, versions, contributors, sections]) => {
          this.canSuggest = suggestMode;
          this.serviceShare.canUseTrackChanges = suggestMode;
          this.canEdit = editMode;
          this.canSeeVersions = versions;
          this.canSeeContributors = contributors;
          this.canSeeSectionsTree = sections;
          
          if(!editMode && !suggestMode) {
            this.shouldTrackChanges = false;
            this.serviceShare.canUseTrackChanges = false;
            this.prosemirrorEditorServie.previewArticleMode.mode = true;
          } else if (!editMode) {
            this.prosemirrorEditorServie.previewArticleMode.mode = true;
          } else {
            this.prosemirrorEditorServie.previewArticleMode.mode = false;
          }
          if(!suggestMode) {
            this.shouldTrackChanges = false;
          }
          
          this.changeDetection.detectChanges();
    }

    this.subscription.add(this.getPermissions().subscribe(setPermissions));

    this.subscription.add(this.serviceShare.EnforcerService.newBeahviorSubject.subscribe(data => {
      if (data == 'updated_policies') {
        // console.log('updated_policies');
        this.sidebar = '';
        this.getPermissions().subscribe(setPermissions)
      }
    }));
    
    this.subscription.add(this.ydocService.currUserRoleSubject.subscribe((userAccess: any) => {
      this.userAccess = userAccess;
    }));
    let articleData = this.route.snapshot.data['product'].data;
 
    this.subscription.add(this.route.paramMap.pipe(
          map((params: ParamMap) =>  params.get('id'))
        ).subscribe((roomName) => {
        this.subscription.add(
          this.authService.currentUser$.subscribe((userInfo) => {
          this.roomName = roomName;
          const [_, urlInfo] = window.location.href.split('#');
          const currUser = this.ydocService.currUser ? this.ydocService.currUser : articleData.collaborators.find((c: any) => c.user_id == userInfo.id);

          const defaultLoad = () => {
            if(!this.canEdit && !this.canSuggest && currUser?.allowed_article_versions?.length && !currUser?.allowed_article_versions.includes("latest")) {
              this.serviceShare.onlyOldVersions = true;
              this.prosemirrorEditorServie.editorContainers = {};
              const version = +currUser.allowed_article_versions[currUser.allowed_article_versions.length - 1];
              this.router.navigate([roomName], { fragment: `${version}` });
              this.ydocService.init(roomName, {data: userInfo}, articleData, version);
              this.serviceShare.oldVersion = true;
            } else {
              this.serviceShare.onlyOldVersions = false;
              this.ydocService.init(roomName!, {data: userInfo}, articleData);
              this.serviceShare.oldVersion = false;
            }
          }

          if(urlInfo) {
            if(urlInfo.includes('-')) {
              let commentId = urlInfo;
              if (commentId && commentId.length > 0) {
                this.commentService.shouldScrollComment = true;
                this.commentService.markIdOfScrollComment = commentId;
              }
            } else {
              const version = Number(urlInfo);
              if(!isNaN(version)) {
                if(currUser?.allowed_article_versions?.length) {
                  if(!currUser?.allowed_article_versions.map((n: string) => +n).includes(version)) {
                    this.serviceShare.onlyOldVersions = true;
                    this.prosemirrorEditorServie.editorContainers = {};
                    const version = +currUser.allowed_article_versions[currUser.allowed_article_versions.length - 1];
                    this.router.navigate([roomName], { fragment: `${version}` });
                    this.ydocService.init(roomName, {data: userInfo}, articleData, version);
                    this.serviceShare.oldVersion = true;
                  } else {
                    this.prosemirrorEditorServie.editorContainers = {};
                    this.ydocService.init(roomName, {data: userInfo}, articleData, version);
                    this.serviceShare.oldVersion = true;
                  }
                } else {
                  this.prosemirrorEditorServie.editorContainers = {};
                  this.ydocService.init(roomName, {data: userInfo}, articleData, version);
                  this.serviceShare.oldVersion = true;
                }
                if(!this.canEdit && !this.canSuggest && currUser?.allowed_article_versions?.length) {
                  this.serviceShare.onlyOldVersions = true;
                }
              } else {
                defaultLoad();
              }
              return;
            }
          }
          defaultLoad();
        }));
      }));

    this.subscription.add(this.ydocService.ydocStateObservable.subscribe(({event, data}) => {
      if (event == 'docIsBuild') {
                /**
         * "settings" :{
              init: "suggest"
            }
         */
        if(this.ydocService.currUser.settings?.init?.editMode) {
          this.turnOnOffTrackChanges(this.ydocService.currUser.settings.init?.editMode == "suggest");
        }
        
        this.ydoc = data.ydoc;
        this.version = data.version;
        this.versionData = data.versionData;
        if(data.versionData) {
          this.versionData.userData = data.userInfo;
          this.serviceShare.oldVersion = true
        }

        let trachChangesMetadata = this.ydocService.trackChangesMetadata!.get(
          'trackChangesMetadata'
        );
        this.shouldTrackChanges = trachChangesMetadata.trackTransactions;

        this.ydocService.trackChangesMetadata?.observe((ymap) => {
          let trackChangesMetadata = this.ydocService.trackChangesMetadata?.get(
            'trackChangesMetadata'
          );
          if (trackChangesMetadata.lastUpdateFromUser !== this.ydoc?.guid) {
            this.shouldTrackChanges = trackChangesMetadata.trackTransactions;
            this.changeDetection.detectChanges();
          }
        });
        this.articleSectionsStructure = data.articleSectionsStructure;
        this.shouldBuild = true;
        this.subscription.add(this.prosemirrorEditorServie.init(data, this.ydocService.shouldReconect).subscribe(() => {
          if (this.commentService.shouldScrollComment) {
            if (this.commentService.scrollToCommentMarkAndSelect()) {
              this.toggleSidebar('comments')
            }
          }
          if(this.serviceShare.oldVersion && this.sidebar != "versions") {
            setTimeout(() => {
              this.toggleSidebar("versions");
              this.changeDetection.detectChanges();
            }, 50);
          }
          if(data.versionData) {
            this.prosemirrorEditorServie.previewArticleMode.mode = true;
            this.docIsBuild = true;
          }
          this.ydocService.shouldReconect = true;
          if(this.ydocService.creatingANewArticle) {
              this.ydocService.articleVersions.push([{
                date: new Date().getTime(),
                snapshot: Y.encodeSnapshot(Y.snapshot(this.ydoc)),
                clientID: this.ydoc.clientID,
                users: [{ 
                  name: data.userInfo.data.name,
                  userColor: data.userInfo.color.userColor,
                  id: data.userInfo.data.id,
                  email: data.userInfo.data.email
                }]
              }])
              this.ydocService.creatingANewArticle = false;
          }
          setTimeout(() => {
            changeVersionSubject.next("sync");            
          }, 500);
        }));
        if (!this.ydocService.articleData) {
          this.subscription.add(this.articlesService
            .getArticleByUuid(this.roomName!)
            .subscribe((data: any) => {
              this.ydocService.setArticleData(data.data);
              this.serviceShare.titleControl.setValue(this.ydocService.articleData.name.replace(this.serviceShare.escapeHtmlTags, ''));
            }));
        } else {
          this.serviceShare.titleControl.setValue(this.ydocService.articleData.name.replace(this.serviceShare.escapeHtmlTags, ''));
        }
        this.articleTemplate = this.ydocService.articleData.layout.name;
      }
    }));

    this.innerWidth = window.innerWidth;
  }

  commentsNumberChange: Subject<number> = new Subject()
  noComments = true;

  ngAfterViewInit(): void {
    this.subscription.add(this.commentService.commentsChangeSubject.subscribe((msg) => {
      let commentNum = Object.values(this.commentService.commentsObj).filter(com => com.resolved == "false").length;
      this.commentsNumberChange.next(commentNum);
      this.noComments = commentNum == 0;
      this.changeDetection.detectChanges();
    }));

    this.changeDetection.detectChanges();
  }

  turnOnOffTrackChanges(bool?: boolean) {
    if (this.prosemirrorEditorServie.previewArticleMode.mode) {
      this.turnOnOffPreviewMode();
    }
    this.serviceShare.DetectFocusService.setSelectionDecorationOnLastSelecctedEditor()
    if (bool == undefined) {
      this.shouldTrackChanges = !this.shouldTrackChanges;
      this.trackChangesData!.trackTransactions =
        !this.trackChangesData!.trackTransactions;
      this.OnOffTrackingChangesShowTrackingSubject.next(this.trackChangesData!);
    } else {
      this.shouldTrackChanges = bool;
      this.trackChangesData!.trackTransactions = bool;
      bool && this.serviceShare.TrackChangesService.changeInEditors();
      this.OnOffTrackingChangesShowTrackingSubject.next(this.trackChangesData!);
    }
  }

  toggleSidebar(section: string) {
    if (!this.sidebarDrawer?.opened || this.sidebar == section) {
      this.sidebarDrawer?.toggle();
    }
    this.sidebar = section;

    // If it's closed - clear the sidebar value
    if (!this.sidebarDrawer?.opened) {
      this.sidebar = '';
    }

    this.serviceShare.CommentsService.shouldCalc = section == 'comments';
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(AddContributorsDialogComponent, {
      width: '665px',
      panelClass: 'contributors-dialog',
      data: {},
      disableClose: false,
    });

    this.subscription.add(dialogRef.afterClosed().subscribe((result) => {
    }));
  }

  print() {
  }

  export() {
    this.subscription.add(this.dialog
      .open(ExportOptionsComponent, {
        width: '532px',
        data: {},
        disableClose: false,
      })
      .afterClosed()
      .subscribe((result) => {
      }));
  }

  openImportDialog() {
    this.subscription.add(this.dialog
      .open(JATSImportModalComponent, {
        width: '665px',
        data: {},
        disableClose: false,
      })
      .afterClosed()
      .subscribe((result) => {
      }));
  }

  submit() {
    if(this.sidebar != "validation") {
      setTimeout(() => {
        this.toggleSidebar("validation");
        this.changeDetection.detectChanges();
      }, 50);
    }

    setTimeout(() => {
      this.serviceShare.triggerValidation();

      const validationSubscription = this.serviceShare.validationResults$.subscribe(results => {
        if (results != 0) {
          validationSubscription.unsubscribe();
          return;
        }

        const journalId = this.ydocService.articleData["journal_id"];
        this.prosemirrorEditorServie.spinSpinner();
        
        this.articleSectionsService.getJournalById(journalId).subscribe({
          next: (journalData: any) => {
            const journalUrl = journalData.data['journal_url'];
            const articleUuid = this.ydocService.articleData.uuid;
            const redirectUrl = `${journalUrl}submit_arpha_document?id=${articleUuid}`;
      
            validationSubscription.unsubscribe();
            window.location.href = redirectUrl;
          },
          error: (err) => {
            console.error('Error fetching journal data:', err);
            this.prosemirrorEditorServie.stopSpinner();
            validationSubscription.unsubscribe();
          }
        });
      });
    }, 100);
  }

  returnToNewestVersion() {
    this.ydocService.versionSubject.next("reconnect");
    changeVersionSubject.next("returnToNewest")
    this.router.navigate([this.roomName]);
    this.ydocService.destroyVersionDoc();
    this.ydocService.shouldReconect = true;
    this.ydocService.lastSelectedVersion = undefined
    this.prosemirrorEditorServie.editorContainers = {};
    this.serviceShare.oldVersion = false;
    this.versionData = undefined;
  }

  ngOnDestroy(): void {
    this.serviceShare.EnforcerService.articlePolicies = [];
    this.serviceShare.titleControl = new UntypedFormControl();
    this.subscription.unsubscribe();
    this.serviceShare.oldVersion = false;
    this.versionData = undefined;
    this.serviceShare.resetServicesData(this.ydocService.shouldReconect);
    if(this.ydocService.shouldReconect) {
      this.ydocService.lastSelectedVersion = undefined;
    }
  }
}
