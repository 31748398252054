import { CdkDragDrop, CdkDropList, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ProsemirrorEditorsService } from '../../services/prosemirror-editors.service';
import { YdocService } from '../../services/ydoc.service';
import { DetectFocusService } from '../../utils/detectFocusPlugin/detect-focus.service';
import { articleSection, basicArticleSection } from '../../utils/interfaces/articleSection';
import { TreeService } from '../tree-service/tree.service';
import { FormBuilderService } from '../../services/form-builder.service';
import { UntypedFormGroup } from '@angular/forms';
import { checkCompatibilitySection } from '@app/editor/utils/articleBasicStructure';
import { SectionLeafComponent } from './section-leaf/section-leaf.component';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-cdk-list-recursive',
  templateUrl: './cdk-list-recursive.component.html',
  styleUrls: ['./cdk-list-recursive.component.scss']
})
export class CdkListRecursiveComponent implements OnInit, OnDestroy, AfterViewInit {

  @Input() articleSectionsStructure: any[];
  @Output() articleSectionsStructureChange = new EventEmitter<any>();

  @Input() nestedList!: boolean;
  @Input() firstNested!: boolean;
  @Input() searching!: boolean;

  @Input() listData!: { expandParentFunc: any, listDiv: HTMLDivElement ,listinstance:SectionLeafComponent };
  @Input() listParentId?: string; // the id of the parent of this node
  focusedId?: string;
  mouseOn?: string;
  @ViewChild('dragDropList', { read: CdkDropList }) dragDropList?: CdkDropList;

  sectionsFormGroups: { [key: string]: UntypedFormGroup } = {};
  connectedTo: CdkDropList[]

  focusIdHold?: string;
  taxonomyData: any;

  subscriber = new Subscription();

  //nodesForms:{[key:string]:FormGroup} = {}

  constructor(
    private formBuilderService: FormBuilderService,
    public treeService: TreeService,
    public ydocService: YdocService,
    public detectFocusService: DetectFocusService,
    public prosemirrorEditorsService: ProsemirrorEditorsService,
    public dialog: MatDialog,
    public changeDetectorRef: ChangeDetectorRef
  ) {
    this.connectedTo = this.treeService.dropListRefs.cdkRefs
  }

  ngAfterViewInit(): void {
    if (this.dragDropList) {
      this.treeService.registerDropListRef(this.dragDropList._dropListRef,this.dragDropList, this.listParentId);
    }
  }

  ngOnInit(): void {
    this.treeService.connectionChangeSubject.subscribe((bool) => {
      this.connectedTo = this.treeService.dropListRefs.cdkRefs
      if (this.dragDropList) {
        this.dragDropList._dropListRef.connectedTo(this.treeService.dropListRefs.refs)
      }
    })
    this.connectedTo = this.treeService.dropListRefs.cdkRefs
    this.treeService.registerConnection(this.listParentId!)
    this.sectionsFormGroups = this.treeService.sectionFormGroups
    const initForms = (node: articleSection) => {
      let nodeForm: UntypedFormGroup = new UntypedFormGroup({});

      const buildForms = (node: articleSection) => {
        let dataFromYMap = this.ydocService.sectionFormGroupsStructures!.get(node.sectionID);
        let defaultValues = dataFromYMap ? dataFromYMap.data : node.defaultFormIOValues;
        let sectionContent = defaultValues ? this.formBuilderService.populateDefaultValues(defaultValues, node.formIOSchema, node.sectionID,node) : node.formIOSchema;

        if(node.type == "complex" && sectionContent.components) {
          this.treeService.optionalRequiredWithSubsection(sectionContent, this.treeService.findNodeById(node.sectionID));
        }
        this.formBuilderService.buildFormGroupFromSchema(nodeForm, sectionContent, node);

        nodeForm.patchValue(defaultValues, { emitEvent: false });
        nodeForm.updateValueAndValidity({ emitEvent: false });
        this.treeService.sectionFormGroups[node.sectionID] = nodeForm;
        this.treeService.setTitleListener(node);
      }

      if(node.title.name == "[MM] Materials" && node.children.length > 0) {
        buildForms(node);
        node.children.forEach((child: articleSection) => {
          if(child.title?.label) {
            buildForms(child);
          }
        })
      } else {
        buildForms(node);
      }
    }
    this.articleSectionsStructure.forEach((node: basicArticleSection, index: number) => {
      const articleSection = this.ydocService.getSectionByID(node.sectionID);
      let nodeForm: UntypedFormGroup = new UntypedFormGroup({});

      if(articleSection.title.name == "[MM] Materials" && node.children.length > 0) {
        initForms(articleSection);
        node.children.forEach((child: basicArticleSection) => {
          const articleSectionChild = this.ydocService.getSectionByID(child.sectionID);
          if(articleSectionChild.title?.label) {
            initForms(articleSectionChild);
          }
        })
      } else {
        initForms(articleSection);
      }
      // let timeout: NodeJS.Timeout;
      // this.ydocService.sectionFormGroupsStructures.observe((ymap) => {
      //   clearTimeout(timeout);
      //   timeout = setTimeout(() => {
      //     let dataFromYMap = this.ydocService.sectionFormGroupsStructures!.get(node.sectionID);
      //     console.log(!dataFromYMap || dataFromYMap?.updatedFrom == this.ydocService.ydoc.guid);
      //     console.log(dataFromYMap, dataFromYMap?.updatedFrom, this.ydocService.ydoc.guid);
          
      //     if (!dataFromYMap || dataFromYMap.updatedFrom == this.ydocService.ydoc.guid) {
      //       console.log("======================================", articleSection.title.label);
            
      //       return
      //     }
      //     console.log("+++++++++++++++++++++++++++++", articleSection.title.label);

      //     Object.keys(nodeForm.controls).forEach((key) => {
      //       nodeForm.removeControl(key);
      //     })
      //     let defaultValues = dataFromYMap.data
      //     let sectionContent = this.formBuilderService.populateDefaultValues(defaultValues, articleSection.formIOSchema, node.sectionID, articleSection, undefined, "From this.articleSectionsStructure.forEach - onInit - CdkListRecursiveComponent");
      //     if(articleSection.type == "complex" && sectionContent.components) {
      //       console.log("this.ydocService.sectionFormGroupsStructures!.observe((ymap) => {");
            
      //       this.treeService.optionalRequiredWithSubsection(sectionContent, this.treeService.findNodeById(node.sectionID));
      //       console.log((sectionContent.components[1] || sectionContent.components[0])?.validate);
      //     }
      //     this.formBuilderService.buildFormGroupFromSchema(nodeForm, sectionContent, articleSection);
      //     this.treeService.setTitleListener(articleSection)
      //   }, 100);
      // })
    });
  }

  drop(event: CdkDragDrop<string[]>) {
    if (!this.treeService.canDropBool[0]) {
      this.treeService.errorSnackbarSubject.next(true);
      return;
    }
    if (event.previousContainer === event.container) {
      if(event.item.data.node.name == "Material") {
        moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        let parent = this.ydocService.getSectionByID(event.item.data.node.parentId);
        if (!parent) {
          parent = this.treeService.findParentNodeWithChildID(event.item.data.node.sectionID)
        }
        //@ts-ignore
        this.treeService.renderMaterials(parent, undefined, event.container.data);
        const materialData = {
          parent, 
          children: event.container.data
        }
        this.treeService.dragNodeChange(event.previousIndex, event.currentIndex, event.previousContainer.id, event.container.id, event.item.data.node, materialData);
        return;
      }
      
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
      this.treeService.dragNodeChange(event.previousIndex, event.currentIndex, event.previousContainer.id, event.container.id, event.item.data.node);
    } else {
      // copy data and moe the items the copy then cehck if the list level is grater than 4 if it is wi dont perform the drop instead wi display an error

      let articleDataCopy = JSON.parse(JSON.stringify(this.treeService.articleSectionsStructure))
      let prevContNewRef: any[]
      let newContNewRef: any[]

      if (this.listParentId == 'parentList') {
        newContNewRef = articleDataCopy;
      }

      if (event.previousContainer.id == 'parentList') {
        prevContNewRef = articleDataCopy;
      }

      let findReferences = (container: any) => {
        container.forEach((el: any) => {
          if (el.sectionID == event.previousContainer.id) {
            prevContNewRef = el.children
          }
          if (el.sectionID == event.container.id) {
            newContNewRef = el.children
          }
          if (el.children && el.children.length > 0) {
            findReferences(el.children)
          }
        })
      }

      findReferences(articleDataCopy);
      //@ts-ignore
      transferArrayItem(prevContNewRef, newContNewRef, event.previousIndex, event.currentIndex);

      let treeNewLevel = 0;
      let countLevel = (num: number, container: any) => {
        let newNum = num + 1
        if (newNum > treeNewLevel) {
          treeNewLevel = newNum;
        }
        container.forEach((el: articleSection) => {
          if (el.type == 'complex') {
            countLevel(newNum, el.children);
          }
        })
      }

      countLevel(0, articleDataCopy);
      if (treeNewLevel > 5 || !checkCompatibilitySection(this.ydocService.getSectionByID(event.container.id)?.compatibility, event.item.data.node)) {
        this.treeService.errorSnackbarSubject.next(true);
      } else {
        transferArrayItem(event.previousContainer.data,
          event.container.data,
          event.previousIndex,
          event.currentIndex);
        this.treeService.dragNodeChange(event.previousIndex, event.currentIndex, event.previousContainer.id, event.container.id, event.item.data.node);
      }
    }
  }

  ngOnDestroy(): void {
    this.treeService.unregisterDropListRef(this.listParentId!);
    this.subscriber.unsubscribe();
  }

}
