import { Component, AfterViewInit, ViewChild, ElementRef } from '@angular/core';

import { ServiceShare } from '../service-share.service';

@Component({
  selector: 'app-custom-pm-element',
  templateUrl: './custom-pm-element.component.html',
  styleUrls: ['./custom-pm-element.component.scss']
})
export class CustomPMElementComponent implements AfterViewInit {
  @ViewChild('pmEditor', { read: ElementRef }) ProsemirrorEditor?: ElementRef;

  constructor(private serviceShare: ServiceShare) {
  }

  ngAfterViewInit(): void {
    this.serviceShare.ProsemirrorEditorsService.renderCustomEditor(this.ProsemirrorEditor.nativeElement, (this.ProsemirrorEditor.nativeElement as HTMLDivElement).parentElement.id, false);
  }
}
