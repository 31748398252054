import {ServiceShare} from "@app/editor/services/service-share.service";
import {Model, newEnforcer} from "casbin";
import {from, Observable} from "rxjs";
import {concatMap, map} from "rxjs/operators";

import {log, matchAction} from "../models/matchers";
import JwtAdapter from "./JwtAdapter";
import {AuthService} from "@core/services/auth.service";
import {ACL} from "../interfaces";

export default class JwtEnforcer {
  casbin: any | null;
  acls: ACL[];
  sub: string = 'asd';
  serviceShare: ServiceShare
  authService: AuthService;

  constructor(acls: ACL[], serviceShare: ServiceShare, authService: AuthService) {
    this.authService = authService;
    this.casbin = null;
    this.serviceShare = serviceShare
    if (!acls) {
      throw new Error("CTOR: JWT ACLS are required!");
    }

    this.acls = acls;
  }

  aclFunction: (requestObj: string, policyObj: string, act: any, eft: any, test, rsub, psub) => boolean = (requestObj, policyObj, act, eft, test, rsub, psub) => {    
    const re = new RegExp(/::|\(|\)/gi);
    
    const policyObjData = policyObj.split(re);

    // if(requestObj.includes(policyObjData[0])) {
    //   console.log("policyObjData", policyObjData);
    //   console.log("requestObj", requestObj);
    //   console.log("policyObj", policyObj);
    //   console.log("act", act);
    //   console.log("eft", eft);
    //   console.log("rsub", rsub);
    //   console.log("psub", psub);
    //   console.log("---------------------------------------------------------------");
    // }
    // if(policyObjData.includes("isCommentOwner")) {
    //     console.log("policyObjData", policyObjData);
    //   console.log("requestObj", requestObj);
    //   console.log("policyObj", policyObj);
    //   console.log("act", act);
    //   console.log("eft", eft);
    //   console.log("rsub", rsub);
    //   console.log("psub", psub);
    //   console.log("---------------------------------------------------------------");
    // }
    
    if(typeof this.serviceShare.CasbinGlobalObjectsService?.[policyObjData[0]] == "function" && requestObj.includes(policyObjData[0])) {
      
      const functionArgs = policyObjData[1] ? policyObjData[1].split(',') : [];
      
      return this.serviceShare.CasbinGlobalObjectsService?.[policyObjData[0]](functionArgs);
    } else if (typeof this.serviceShare.CasbinGlobalObjectsService?.[policyObjData[0]] == "object") {
      const functionArgs = policyObjData[2].split(',');
      
      return this.serviceShare.CasbinGlobalObjectsService?.[policyObjData[0]]?.[policyObjData[1]](...functionArgs);
    }
    return false;
  }

  setup(model: Model) {
    return from(newEnforcer(model, new JwtAdapter(this.acls))).pipe(
      concatMap((casbin) => {
        this.casbin = casbin;
        this.casbin.addFunction("matchAction", matchAction);
        this.casbin.addFunction("log", log);
        return from(this.casbin.addFunction('aclFunction', this.aclFunction)).pipe(
          map(() => this)
        );
      })
    );
  }

  enforce(sub: string, obj: string, act: string): Observable<boolean> {
    if (!this.casbin) {
      throw new Error("Run setup() before enforcing!");
    }

    //casbin.enforce return a promise
    return from(this.casbin.enforce(sub, obj, act)) as Observable<boolean>;
  }

  enforcePromise(sub: string, obj: string, act: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.casbin.enforce(sub, obj, act).then((data: any) => {

        resolve(data)
      })
    })
  }

  enforceSync(sub: string, obj: string, act: string): boolean {
    console.log(sub, obj, act);
    
    const asd=  this.casbin.enforceSync(sub, obj, act);
    console.log("asd",asd);
    
    return asd
  }
}
