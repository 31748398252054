import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { debounceTime, switchMap } from 'rxjs/operators';

import { AllUsersService, authorListData, contributorData, roleMapping, mapping } from '@app/core/services/all-users.service';
import { ServiceShare } from '@app/editor/services/service-share.service';
import { EditContributorComponent } from './edit-contributor/edit-contributor.component';
import { SendInvitationComponent } from './send-invitation/send-invitation.component';

@Component({
  selector: 'app-add-contributors-dialog',
  templateUrl: './add-contributors-dialog.component.html',
  styleUrls: ['./add-contributors-dialog.component.scss'],
})
export class AddContributorsDialogComponent implements AfterViewInit, OnDestroy {
  ownerSettingsForm: UntypedFormGroup;

  searchFormControl = new UntypedFormControl('')

  showError = false;
  public access: any[] = [];

  searchData: contributorData[];
  contributersData: contributorData[];
  invitingNewUser = false;

  authorsList:authorListData[]

  searchResults: any[] = []

  currentUser?: any;
  // public allUsers!: any[];
  public searchText: any;

  emailRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  loading = false;

  mapping = mapping;

  colorFunc = this.sharedService.YdocService.setUserColor;

  @ViewChild('searchUsersInput', { read: ElementRef }) searchUsersInput?: ElementRef;
  constructor(
    public allUsersService: AllUsersService,
    private dialogRef: MatDialogRef<AddContributorsDialogComponent>,
    public formBuilder: UntypedFormBuilder,
    public dialog: MatDialog,
    public sharedService: ServiceShare,
    private ref:ChangeDetectorRef
  ) {
    this.ownerSettingsForm = formBuilder.group({
      accessAdding: false,
      disableOptionsPrint: false,
    });
    this.searchFormControl.valueChanges.pipe(
      debounceTime(300),
      switchMap((value:string) => {
        if(value?.length) {
          this.loading = true;
        } else {
          this.loading = false;
          // this.searchData = [];
          // this.searchResults = [];
        }
        // this.loading = false;
        this.searchData = [];
        this.searchResults = [];
        return this.allUsersService.getAllUsersV2({page:1, pageSize:10, 'filter[search]': value})
    })).subscribe({ 
          next: (val:any)=>{
            this.loading = false;
            if(val.meta.filter && val.meta.filter.search && val.meta.filter.search !== 'null'){
              console.log();
              
              const results = val.data.filter(user => !this.collaborators.collaborators.find((col) => col.email == user.email));
              if(results.length > 0) {
                if(
                  this.emailRegex.test(val.meta.filter.search) && 
                  !results.find(u => u.email == val.meta.filter.search) && 
                  !this.collaborators.collaborators.find((col) => col.email == val.meta.filter.search)
                ) {
                  results.unshift({ 
                    email: val.meta.filter.search,
                    id: null,
                    name: val.meta.filter.search
                  });
                }
                this.searchData = results;
                this.searchResults = results;
                this.invitingNewUser = false;
              } else {
                if(this.emailRegex.test(val.meta.filter.search)) {
                  const result = {
                    email: val.meta.filter.search,
                    id: null,
                    name: val.meta.filter.search
                  }
                  this.searchData = [result];
                  this.searchResults = [result];
                  this.invitingNewUser = true;
                } else {
                  this.searchData = [];
                  this.searchResults = [];
                }
              }
            }else{
              this.searchData = [];
              this.searchResults = [];
            }      
          },
          error: (err) => {
            console.error(err);
          }
        });
   (document.getElementsByClassName('spinner-container')[0] as HTMLElement).style.zIndex = '1111';
  }

  hideResults(){
    this.searchResults = [];
  }

  showResult(){
    let val = this.searchFormControl.value;
    if(!val || val.length == 0) return;
    this.searchResults = this.searchData;
  }

  editContr(contrData: any) {
    const dialogRef = this.dialog.open(EditContributorComponent, {
      maxWidth: '80%',
      panelClass: 'contributors-dialog',
      data: { contrData },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.edited) {
        let editedContributors = [...this.collaborators.collaborators]
        let authorsListCopy: authorListData[] = [...this.authorsList];
        let userIndex
        if(contrData.id){
          userIndex = editedContributors.findIndex((user) => user.id == contrData.id)
        }else{
          userIndex = editedContributors.findIndex((user) => user.email == contrData.email)
        }
        let userInCollaboratorsArr = editedContributors[userIndex]
        if (result.removed && userInCollaboratorsArr) {
          this.sharedService.ProsemirrorEditorsService.spinSpinner()
          editedContributors.splice(userIndex, 1)
          userInCollaboratorsArr.type = mapping[userInCollaboratorsArr.access];

          let body = {
            "article": {
              "id": this.sharedService.YdocService.articleData.uuid,
              "title": this.sharedService.YdocService.articleData.name
            },
            "invited": [userInCollaboratorsArr]
          };

          this.allUsersService.removeCollaborator(body).subscribe({
            next: (res: any) => {
              this.sharedService.ProsemirrorEditorsService.stopSpinner()
              if(userInCollaboratorsArr.role ==  'author' || userInCollaboratorsArr.role == 'author_commenter'){
                if(userInCollaboratorsArr.id&&authorsListCopy.some(x=>x.authorId == userInCollaboratorsArr.id)){
                  authorsListCopy = authorsListCopy.filter(x=>x.authorId != userInCollaboratorsArr.id)
                }else if(!userInCollaboratorsArr.id&&userInCollaboratorsArr.email&&authorsListCopy.some(x=>x.authorEmail == userInCollaboratorsArr.email)){
                  authorsListCopy = authorsListCopy.filter(x=>x.authorEmail != userInCollaboratorsArr.email)
                }
              }
              if(authorsListCopy.length!=this.authorsList.length){
                this.sharedService.YdocService.collaborators.set('authorsList', authorsListCopy);
              }
              this.sharedService.YdocService.collaborators.set('collaborators', { collaborators: editedContributors })
            },
            error: (err) => {
              console.error(err);
              this.sharedService.ProsemirrorEditorsService.stopSpinner();
            }
          });
        } else /*if (result.access)*/ {
          if((userInCollaboratorsArr.role ==  'author' || userInCollaboratorsArr.role == 'author_commenter')&&result.role == 'contributor'){
            let prop
            let val
            if(userInCollaboratorsArr.id){
              prop = 'authorId'
              val = userInCollaboratorsArr.id
            }else{
              prop = 'authorEmail'
              val = userInCollaboratorsArr.email
            }
            let indexOfAuthor = authorsListCopy.findIndex(user=>user[prop] == val);
            if(indexOfAuthor>=0){
              authorsListCopy.splice(indexOfAuthor,1);
            }
          }else if(userInCollaboratorsArr.role ==  'contributor'&&(result.role ==  'author' || result.role == 'author_commenter')){
            authorsListCopy.push({authorEmail:userInCollaboratorsArr.email,authorId:userInCollaboratorsArr.id});
          }
          editedContributors[userIndex].access = editedContributors[userIndex].is_owner ? "Owner" : roleMapping[result.role];
          editedContributors[userIndex].role = result.role;
          editedContributors[userIndex].affiliations = result.affiliations;
          editedContributors[userIndex].email = result.email;
          editedContributors[userIndex].first_name = result.firstName;
          editedContributors[userIndex].last_name = result.lastName;
          editedContributors[userIndex].name = `${result.firstName} ${result.lastName}`;
          editedContributors[userIndex].is_co_author = result.isCoAuthor;

          // if(result.access !== 'Owner') {
            this.sharedService.ProsemirrorEditorsService.spinSpinner();
            const edited = editedContributors[userIndex];
            // edited.type = accessMaping[result.access];

            let body = {
              "article": {
                "id": this.sharedService.YdocService.articleData.uuid,
                "title": this.sharedService.YdocService.articleData.name
              },
              "invited": [edited]
            };

            this.allUsersService.editCollaborator(body).subscribe({
              next: (res: any) => {
                this.sharedService.YdocService.collaborators.set('collaborators', { collaborators: editedContributors });
                this.sharedService.YdocService.collaborators.set('authorsList', authorsListCopy);
                this.setCollaboratorsData(this.sharedService.YdocService.collaborators.get("collaborators"));
                this.sharedService.ProsemirrorEditorsService.stopSpinner();
              },
              error: (err) => {
                console.error(err);
                this.sharedService.ProsemirrorEditorsService.stopSpinner();
              }
            });
          // } else {
          //   this.sharedService.YdocService.collaborators.set('collaborators', { collaborators: editedContributors });
          //   this.sharedService.YdocService.collaborators.set('authorsList', authorsListCopy)
          // }
        }
      }
    });
  }

  drop(event: CdkDragDrop<any[]>) {
    let authorsCopy = [...this.authorsList];
    const movedAuthor = authorsCopy[event.previousIndex];

    moveItemInArray(authorsCopy, event.previousIndex, event.currentIndex);
    moveItemInArray(this.authors, event.previousIndex, event.currentIndex);
    
    this.sharedService.ProsemirrorEditorsService.spinSpinner();
    this.allUsersService.moveCollaborator({ 
      article_id: this.sharedService.YdocService.articleData.uuid, 
      user_id: movedAuthor.authorId, 
      position: event.currentIndex + 1 
    }).subscribe({
      next: (res: any) => {
        this.sharedService.ProsemirrorEditorsService.stopSpinner();
        this.sharedService.YdocService.collaborators.set('authorsList',authorsCopy);
      },
      error: (err) => {
        this.sharedService.ProsemirrorEditorsService.stopSpinner();
        console.error(err);
      }
    });
  }

  filterSearchResults(filterVal: string) {
    if (this.collaborators && this.currentUser && this.searchData) {
      return this.searchData.filter((user) => { return (user.email.includes(filterVal.toLocaleLowerCase()) && user.email != this.currentUser.email && !this.collaborators.collaborators.find((col) => col.email == user.email)) })
    } else {
      return []
    }
  }

  search(inputText: HTMLInputElement) {
    let input = inputText.value
  }

  collaborators?: { collaborators: any[] }
  isOwner = false;

  authors:any[]
  contributors:any[]


  setCollaboratorsData(collaboratorsData: any) {
    setTimeout(() => {
      this.collaborators = collaboratorsData
      this.authorsList = this.sharedService.YdocService.collaborators.get('authorsList');

      this.authors = this.authorsList.map((user)=>{
        let prop
        let val
        if(user.authorId){
          prop = 'id'
          val = user.authorId
        }else{
          prop = 'email'
          val = user.authorEmail
        }
        return this.collaborators.collaborators.find(x=>x[prop] == val);
      })

      this.contributors = this.collaborators.collaborators.filter((user)=>{
        let prop
        let val
        if(user.id){
          prop = 'authorId'
          val = user.id
        }else{
          prop = 'authorEmail'
          val = user.email
        }
        return !this.authorsList.some(x=>x[prop] == val);
      })

      if (this.currentUser) {
        this.checkIfCurrUserIsOwner();
      }
    }, 30)
  }

  checkIfCurrUserIsOwner() {
    let user = this.collaborators.collaborators.find((col) => { return col.id == this.currentUser.id });
    // if(user.name != this.currentUser.name) {
    //   const collaborators = [];
    //   this.collaborators.collaborators.forEach((collaborator: any) => {
    //     if(collaborator.name != this.currentUser.name && collaborator.id == this.currentUser.id) {
    //       collaborator.name = this.currentUser.name;
    //     }
    //     collaborators.push(collaborator);
    //   })
    //   this.collaborators.collaborators = collaborators;
    //   this.sharedService.YdocService.collaborators.set("collaborators", { collaborators: collaborators });
    // }
    this.sharedService.EnforcerService.enforceAsync('is-admin','admin-can-do-anything').subscribe((admin)=>{
      if(admin){
        this.isOwner = true
      }else{
        if (user?.access == 'Owner') {
          this.isOwner = true
        }
      }
    })
  }

  collaboratorstSubs: Subscription
  ngAfterViewInit(): void {
    this.sharedService.AuthService.currentUser$.subscribe((response) => {
      this.currentUser = response;
      if (this.collaborators) {
        this.checkIfCurrUserIsOwner()
      }
    });
    this.collaboratorstSubs = this.sharedService.YdocService.collaboratorsSubject.subscribe((data) => {
      this.setCollaboratorsData(data)
    });
    this.setCollaboratorsData(this.sharedService.YdocService.collaborators.get('collaborators'));
    this.searchUsersInput.nativeElement.focus();
    this.ref.detectChanges();
  }
  closeDialog() {
    this.dialogRef.close();
  }
  submitOwnerSettingsForm() {
  }

  dataIsLoaded = true;




  sendAllSelectContributers() {

  }
  openAddContrDialog(contributor: any) {
    const dialogRef = this.dialog.open(SendInvitationComponent, {
      maxWidth: '80%',
      panelClass: 'contributors-dialog',
      data: { contributor: [contributor] },
    });

    dialogRef.afterClosed().subscribe((result:{
      'usersChipList': any,
      'notifyingPeople': any,
      // 'accessSelect': string,
      'roleSelect': string,
      'affiliations':{
        affiliation:string,
        city:string,
        country:string
      }[],
      'message': string
    }) => {
      if (result&&result.usersChipList.length > 0 && this.collaborators) {
        this.sharedService.ProsemirrorEditorsService.spinSpinner();
        let collaboratorsCopy = [...this.collaborators.collaborators];
        result.usersChipList.forEach((newColaborator) => {
          if(newColaborator.id == null) {
            const id = newColaborator.email;
            collaboratorsCopy.push({ ...newColaborator,role:result.roleSelect,affiliations:result.affiliations, id });
          } else {
            collaboratorsCopy.push({ 
              name: newColaborator.name, 
              first_name: newColaborator.first_name,
              last_name: newColaborator.last_name,
              email: newColaborator.email,
              id: newColaborator.id,
              role: result.roleSelect,
              access: roleMapping[result.roleSelect],
              affiliations: result.affiliations
            });
          }
        })
        let authorsListCopy:authorListData[] = [...this.authorsList];
        if(result.roleSelect == "author" || result.roleSelect == "author_commenter"){
          authorsListCopy.push(...result.usersChipList.map((user: any)=>{
            if(user.id == null) {
              const authorId = user.email;
              return { authorId, authorEmail:user.email };
            } else {
              return { authorId:user.id, authorEmail:user.email };
            }
          }));
        }
        let articleData = {
          "id": this.sharedService.YdocService.articleData.uuid,
          "title": this.sharedService.YdocService.articleData.name
        }
        let postBody = {
          "article": articleData,
          "message": result.message,
          "invited": result.usersChipList.map((x: any, i: number) => {
            const contributor = collaboratorsCopy.find(c => c.id == x.id);

            if(contributor) {
              return {
                id: contributor.id,
                email: contributor.email,
                name: contributor.first_name,
                role: contributor.role.toLocaleLowerCase(),
                affiliations: contributor.affiliations,
              }
            } else {
              return {
                ...x,
                role: result.roleSelect.toLocaleLowerCase(),
                affiliations: result.affiliations
              }
            }
          }),
        }
        console.log(postBody);
        
        this.allUsersService.sendInviteInformation(postBody).subscribe(
          (res: any) => {
            this.sharedService.YdocService.collaborators.set('collaborators', { collaborators: collaboratorsCopy });
            this.sharedService.YdocService.collaborators.set('authorsList', authorsListCopy);
            this.setCollaboratorsData(this.sharedService.YdocService.collaborators.get("collaborators"));
            this.sharedService.ProsemirrorEditorsService.stopSpinner()
          },
          (err) => {
            console.error(err)
            this.sharedService.ProsemirrorEditorsService.stopSpinner()
          }
        )
      }
      this.searchFormControl.setValue(null)
    });
  }

  hasContributors(contributors: any[]) {
    return contributors.length && contributors.filter(c => !this.allUsersService.isHidden(c.role)).length;
  }

  ngOnDestroy(): void {
   (document.getElementsByClassName('spinner-container')[0] as HTMLElement).style.zIndex = '999';
    if (this.collaboratorstSubs) {
      this.collaboratorstSubs.unsubscribe()
    }
  }
}
