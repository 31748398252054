import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ServiceShare } from '../services/service-share.service';

@Component({
  selector: 'app-versions-section',
  templateUrl: './versions-section.component.html',
  styleUrls: ['./versions-section.component.scss'],
})
export class VersionsSectionComponent implements AfterViewInit, OnDestroy {

  versions = [];

  searchControl = new UntypedFormControl('');

  observeFunc: () => void;

  @ViewChild('versionsInput', { read: ElementRef }) versionsInput?: ElementRef;

  constructor(private sharedService: ServiceShare, private changeDetector: ChangeDetectorRef) {
    this.observeFunc = () => {
      if(!this.searchControl.value.trim()) {
        this.getVersions();
        this.changeDetector.detectChanges();
      }
    };

    this.sharedService.YdocService.articleVersions.observe(this.observeFunc);
    this.getVersions();
  }

  ngAfterViewInit(): void {
    this.searchControl.valueChanges.subscribe((value: string) => {
      const num = Number(value);
      
      if(value && value.trim() && num) {
        this.getVersions(num);
      } else {
        this.getVersions();
      }
    })
  }

  getVersions(index?: number) {
    let versions: any[] = [];
    const allowedVersions = this.sharedService.YdocService.currUser.allowed_article_versions.map((n: string) => +n);
    this.sharedService.YdocService.articleVersions.forEach((version: any, index: number) => {
      if(!allowedVersions.includes("latest")) {
        if(allowedVersions.length && !allowedVersions.includes(index)) return;
  
        if(version.restored) {
          const restoredDate = new Date(version.restored).toISOString();
          versions.unshift({date: new Date(version.date).toISOString(), snapshot: version.snapshot, index, users: version.users, restored: restoredDate});
        } else {
          versions.unshift({date: new Date(version.date).toISOString(), snapshot: version.snapshot, index, users: version.users});
        }
      }
    });
    if(!allowedVersions.includes("latest")) {
      const name = this.sharedService.ProsemirrorEditorsService.userInfo.data.name;
      const id = this.sharedService.ProsemirrorEditorsService.userInfo.data.id;
      const userColor = this.sharedService.ProsemirrorEditorsService.userInfo.color.userColor;
      if(!isNaN(index)) {
        this.versions = versions[index] ? [versions.reverse()[index]] : [];
      } else {
        this.versions = versions;
      }
      if(!this.sharedService.onlyOldVersions) {
        versions.unshift({date: new Date().toISOString(), snapshot: undefined, index: versions.length, users: [{name, id, userColor} ]});
      }
    } else {
      this.versions = [];
    }
  }

  ngOnDestroy(): void {
    this.sharedService.YdocService.articleVersions.unobserve(this.observeFunc);
  }
}
