import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ServiceShare } from '@app/editor/services/service-share.service';
import { EchoService } from 'ngx-laravel-echo';
import { ReplaySubject, combineLatest } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { APP_CONFIG, AppConfig } from '@core/services/app-config';
import { authorListData, roleMapping } from '@app/core/services/all-users.service';
import { Router } from '@angular/router';
import { changeVersionSubject } from '../../../../y-prosemirror-src/plugins/sync-plugin.js'

export interface notificationEvent {
  date: number,
  event: string,
  status: string,
  eventId: string,
  new: boolean,
  link?: string,
  metaData?:any,
  error?: string,
  data?: any
  docName?: string,
}

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  notificationsBehaviorSubject = new ReplaySubject<notificationEvent[]>();

  localNotifications: notificationEvent[] = []
  allNotifications: notificationEvent[] = []

  getOldNotificationsIds(): string[] {
    let oldNotifications = sessionStorage.getItem('oldevents');
    if (oldNotifications) {
    return JSON.parse(oldNotifications)
    } else {
      return []
    }
  }

  addLocalNotification(event:notificationEvent){
    this.localNotifications.push(event);
    this.passNotifications();
  }

  setEventAsOld(eventid: string) {
    let oldNotifications: any = sessionStorage.getItem('oldevents');
    let oldevents: string[]
    if (!oldNotifications) {
      oldevents = [];
    } else {
      oldevents = JSON.parse(oldNotifications)
    }
    if (!oldevents.includes(eventid)) {
      oldevents.push(eventid);
    }
    sessionStorage.setItem('oldevents', JSON.stringify(oldevents))
    setTimeout(() => {
      this.passNotifications()
    }, 10)
  }

  constructor(
    private ServiceShare: ServiceShare,
    private http: HttpClient,
    private readonly echoService: EchoService,
    private router: Router,
    @Inject(APP_CONFIG) private config: AppConfig,
  ) {

    ServiceShare.AuthService.currentUser$.subscribe((user)=>{

      const token = ServiceShare.AuthService.getToken();
      this.echoService.echo.connector.options.auth.headers['Authorization'] = 'Bearer ' + token;
      if(user) {
        this.echoService.join(`task_manager:private-notifications.${user.id}`, 'public')
          .listen(`task_manager:private-notifications.${user.id}`, '.TaskCreatedEvent')
          .subscribe(data => {
            this.handleTaskUpdatesEvents(data)
          })

        this.echoService.join(`task_manager:private-notifications.${user.id}`, 'public')
          .listen(`task_manager:private-notifications.${user.id}`, '.TaskUpdateEvent')
          .subscribe(data => {
            this.handleTaskUpdatesEvents(data)
          });

        let timeout: NodeJS.Timeout;
        
        this.echoService.join(`task_manager:private-notifications.all`, 'public')
          .listen(`task_manager:private-notifications.all`, '.article:updated')
          .subscribe(data => {
            console.log('Notification [article:updated]', data);
            if(data.data.uuid == this.ServiceShare.YdocService.articleData?.uuid) {
              if(this.ServiceShare.YdocService.articleData.name != data.data.name) {
                this.ServiceShare.YdocService.articleData.name = data.data.name;
                this.ServiceShare.YdocService.articleData.updated_at = data.data.updated_at;
              } else {
                const collaboratorsFromEvent = data.data.collaborators.data || data.data.collaborators;
                this.ServiceShare.YdocService.articleData.collaborators = collaboratorsFromEvent;

                if(collaboratorsFromEvent) {
                  let changeInVersionArr = false;
                  let collaborators = JSON.parse(JSON.stringify(ServiceShare.YdocService.getCollaborators()));
                  let authorsList: authorListData[] = collaboratorsFromEvent.map((user: any)=>{
                    if(user.role == "author" || user.role == "author_commenter") {
                      if(user.user_id == null) {
                        const authorId = user.user_email;
                        return { authorId, authorEmail:user.user_email };
                      } else {
                        return { authorId:user.user_id, authorEmail:user.user_email };
                      }
                    }
                  }).filter((c: any) => c != undefined);

                  const userFromCollaborators = collaboratorsFromEvent.find(u => u.user_id == this.ServiceShare.YdocService.currUser.id);
                  if(userFromCollaborators &&  this.ServiceShare.compareObjects(userFromCollaborators.allowed_article_versions, this.ServiceShare.YdocService.currUser.allowed_article_versions)) {
                    changeInVersionArr = true;
                  }
                  
                  if(collaborators.length > collaboratorsFromEvent.length) {
                    const updatedCollaborators = [];
                    collaborators.forEach((c: any) => {
                      if(collaboratorsFromEvent.find(coll => coll.user_id == c.id)) {
                        updatedCollaborators.push(c);
                      }
                    })
                    collaborators = updatedCollaborators;
                  }
                  
                  collaboratorsFromEvent.forEach((c: any) => {
                    const collaborator = collaborators.find((cr: any) => cr.id == c.user_id);
                    if(!collaborator) {
                      collaborators.push({
                        name: c.user_name,
                        email: c.user_email,
                        first_name: c.user_first_name,
                        last_name: c.user_last_name,
                        role: c.role,
                        is_owner: c.is_owner,
                        position: c.position,
                        access: c.is_owner ? "Owner" : roleMapping[c.role],
                        affiliations: c.affiliations.data ? c.affiliations.data.map((a: any) => ({
                          city: a.city,
                          country: a.country,
                          affiliation: a.affiliation,
                      })) : [],
                        type: c.type,
                        auth_role: c.auth_role,
                        settings: c.settings,
                        hide_me_from_user: c.hide_me_from_user,
                        hide_my_comments_from_user: c.hide_my_comments_from_user,
                        allowed_article_versions: c.allowed_article_versions,
                        is_co_author: c.is_co_author,
                        role_label: c.role_label,
                        id: c.user_id,
                      })
                    } else {
                      collaborator.role = c.role;
                      collaborator.access = c.is_owner ? "Owner" : roleMapping[c.role];
                      collaborator.allowed_article_versions = c.allowed_article_versions;
                      collaborator.hide_my_comments_from_user = c.hide_my_comments_from_user;
                      collaborator.hide_me_from_user = c.hide_me_from_user;
                      collaborator.settings = c.settings;
                      collaborator.auth_role = c.auth_role;
                      collaborator.type = c.type;
                      collaborator.position = c.position;
                      collaborator.is_owner = c.is_owner;
                      collaborator.last_name = c.user_last_name;
                      collaborator.first_name = c.user_first_name;
                      collaborator.email = c.user_email;
                      collaborator.name = c.user_name;
                      collaborator.role_label = c.role_label;
                      collaborator.is_co_author = c.is_co_author;
                    }
                  });
                  
                  if(this.ServiceShare.YdocService.currUser.access != "Owner") {
                    this.ServiceShare.YdocService.currUser = collaborators.find((user) => user.id == this.ServiceShare.YdocService.currUser.id);
                  }

                  if(this.ServiceShare.compareObjects(this.ServiceShare.YdocService.getCollaborators(), collaborators)) {
                    this.ServiceShare.YdocService.collaborators.set("collaborators", { collaborators: collaborators });
                  }
  
                  if(this.ServiceShare.compareObjects(this.ServiceShare.YdocService.getAuthorsList(), authorsList)) {
                    this.ServiceShare.YdocService.collaborators.set("authorsList", authorsList);  
                  }

                  this.ServiceShare.YdocService.checkHiddenCommentsAndUsers();

                  if(changeInVersionArr) {
                    if(this.ServiceShare.YdocService.currUser.allowed_article_versions.includes("latest")) {
                      this.ServiceShare.YdocService.versionSubject.next("reconnect");
                      changeVersionSubject.next("returnToNewest");
                      this.router.navigate([this.ServiceShare.YdocService.articleData?.uuid]);
                      this.ServiceShare.YdocService.destroyVersionDoc();
                      this.ServiceShare.YdocService.shouldReconect = true;
                      this.ServiceShare.YdocService.lastSelectedVersion = undefined
                    }
                  }
                }
                clearTimeout(timeout);
                timeout = setTimeout(() => {
                  const policiesSubject = this.ServiceShare.EnforcerService.policiesChangeSubject.asObservable().pipe(take(1));
                  combineLatest([
                    this.ServiceShare.ArticlesService.getArticleDomainPolicies(data.data.uuid), 
                    policiesSubject
                  ]).subscribe({
                    next: ([res, policies]) => {
                      if(this.ServiceShare.compareObjects(res, policies)) {
                        this.ServiceShare.EnforcerService.policiesChangeSubject.next(res);
                      }
                    },
                    error: (err) => {
                      console.error(err);
                    }
                  });
                }, 1000);
              }
            }
          })

        this.echoService.join(`task_manager:private-notifications.all`, 'public')
          .listen(`task_manager:private-notifications.all`, '.user:updated')
          .subscribe(data => {
            console.log('Notification [user:updated]', data);
            if(this.ServiceShare.YdocService.userInfo && this.ServiceShare.YdocService.articleData && this.ServiceShare.YdocService.userInfo.data.id == data.data.id) {
              this.ServiceShare.ArticlesService.getArticleDomainPolicies(data.data.uuid).subscribe({
                next: (res) => {
                  this.ServiceShare.EnforcerService.policiesChangeSubject.next(res);
                },
                error: (err) => {
                  console.error(err);
                }
              })
            }
          })
      }
    })
    ServiceShare.shareSelf('NotificationsService', this)
  }

  getAllNotifications() {
    this.http.get(`${this.config.apiUrl}/event-dispatcher/tasks`).pipe(map((data: any[]) => {
      this.allNotifications
      let oldNotifictions = this.getOldNotificationsIds();

      let notificationsFromBackend: notificationEvent[] = []
      data.forEach(task => {
        let date = new Date(task.created_at).getTime();
        let event = task.type === 'pdf.export' ? 'PDF export' :  task.type;
        let docName = task.data?.data?.article_title;
        let status = task.status;
        let eventId = task.task_id;
        const data =  task.data?.data || null;
        let isNew = !oldNotifictions.includes(event.eventId)
        let notification: notificationEvent = {
          date, event, docName, status, eventId, new: isNew, data
        }
        if (task.type == 'pdf.export' && task.status == 'DONE') {
          notification.link = task.data.data ? task.data.data.url : task.data.url;
        }
        if (task.data?.error) {
          try {
            notification.error = task.data.error.slice(1, task.data.error.length - 1)
          } catch (error) {
            notification.error = task.data.error.message.slice(1, task.data.error.length - 1)
          }
        }
        notificationsFromBackend.push(notification)
      })
      this.allNotifications = notificationsFromBackend
      this.passNotifications();
    })).subscribe()
  }

  handleTaskUpdatesEvents(eventData) {
    if (eventData.task.type == "pdf.export") {
      let date = new Date(eventData.task.created_at);
      let isNew = !this.getOldNotificationsIds().includes(eventData.task.task_id)
      let task: notificationEvent = {
        event: 'PDF export',
        docName: this.ServiceShare.YdocService.articleData.name,
        data: eventData.task.data?.data || null,
        date: date.getTime(),
        eventId: eventData.task.task_id,
        status: eventData.task.status,
        new: isNew
      }
      if (eventData.task.status == 'DONE') {
        let url = eventData.task.data.data.url;
        task.link = url;
      }else if(eventData.task.status == 'FAILED'){
        task.metaData = [eventData.task.data.error]
        task.link = 'open_pdf_render_errors'
      }
      if (this.allNotifications.findIndex((n) => n.eventId == task.eventId)!=-1) {
        this.updateEventData(task)
      } else {
        this.newNotificationEvent(task)
      }
    }
  }
  viewNotification(event: notificationEvent) {
    if (event.link) {
      if(event.link == 'open_jats_render_errors'||event.link == 'open_pdf_render_errors'||event.link == 'open_jats_render_style_warnings'){
        const errors = event.metaData.slice(0, 4);
        this.ServiceShare.openJatsErrorsDialog(errors);
      }else{
        window.open(event.link)
      }
    }
    else if (event.error && event.event === 'PDF export') {
      this.ServiceShare.openJatsErrorsDialog([event.error]);
    }
    let eventid = event.eventId;
    this.setEventAsOld(eventid);
  }

  passNotifications() {
    let oldNotifications = this.getOldNotificationsIds()
    this.allNotifications.forEach((notification) => {
      if (oldNotifications.includes(notification.eventId)) {
        notification.new = false;
      } else {
        notification.new = true;
      }
    })
    this.localNotifications.forEach((notification) => {
      if (oldNotifications.includes(notification.eventId)) {
        notification.new = false;
      } else {
        notification.new = true;
      }
    })
    let allNotificationArr = [...this.allNotifications,...this.localNotifications];
    this.notificationsBehaviorSubject.next(allNotificationArr);
  }

  updateEventData(event: notificationEvent) {
    this.allNotifications = this.allNotifications.map((task) => {
      if (task.eventId == event.eventId) {
        return event
      }
      return task
    })
    this.passNotifications();
  }

  newNotificationEvent(event: notificationEvent) {
    this.allNotifications.push(event);
    this.passNotifications();
  }
}
