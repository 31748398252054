import { Injectable, Input } from '@angular/core';
import { editorContainer } from '@app/editor/services/prosemirror-editors.service';
import { ServiceShare } from '@app/editor/services/service-share.service';
import { schema } from '@app/editor/utils/Schema';
import { ySyncPluginKey } from '../../../y-prosemirror-src/plugins/keys.js';
import { Subscription } from 'rxjs';
import { authorListData, contributorData } from '@app/core/services/all-users.service.js';

@Injectable({
  providedIn: 'root'
})
export class CollaboratorsService {

  affiliationsSymbolMapping = ["‡", "§", "|", "¶", "#", "¤", "«", "»", "˄", "˅", "¦", "ˀ", "ˁ", "₵", "ℓ", "₰", "₱", "₳", "₴", "₣", "₮", "₦", "₭", "₲", "‽", "₩", "₸"]

  subscription = new Subscription();
  collaborators
  authorsList
  headEditorContainer?:editorContainer

  @Input()  versionData: {
    snapshot: any,
    prevSnapshot: any,
    returnToNewest: boolean
  } | undefined;

  constructor(
    private serviceShare:ServiceShare,
  ) {
    this.serviceShare.shareSelf('CollaboratorsService',this)
  }

  getAffiliationKey(affiliation){
    return affiliation.affiliation + affiliation.city + affiliation.country;
  }

  fillAffiliationsData(authors,collaborators,affiliationsFound,authorsAndSymbols){
    authors?.forEach((author)=>{
      let prop
      let val
      if(author.authorId){
        prop = 'id';
        val = author.authorId;
      }else{
        prop = 'email';
        val = author.authorEmail;
      }

      let collaborator = collaborators?.find((user)=>user[prop] == val);
      if(collaborator){
        let userAffiliationSymbols = []
        if(collaborator.affiliations){
          collaborator.affiliations.forEach((affiliation)=>{
            let affilKey = this.getAffiliationKey(affiliation);
            let affilSymbol
            if(!affiliationsFound.some(x=>x.key == affilKey)){
              affilSymbol = this.affiliationsSymbolMapping[affiliationsFound.length]
              affiliationsFound.push({key:affilKey,displayTxt:`${affilSymbol} ${affiliation.affiliation}, ${affiliation.city}, ${affiliation.country}`,symbol:affilSymbol,affiliation})
            }else{
              affilSymbol = affiliationsFound.find(x=>x.key == affilKey).symbol
            }
            userAffiliationSymbols.push(affilSymbol);
          })
        }
        authorsAndSymbols.push({collaborator,affiliationSymbols:userAffiliationSymbols});
      }else{
        console.error('No callaborator with '+prop+" "+val);
      }
    })
  }

  renderMetaNodeInHeadEditor(collaborators:any[],authors:authorListData[]){
    let authorsAndSymbols:{collaborator:any,affiliationSymbols:string[]}[] = []
    let affiliationsFound:{key:string,displayTxt:string,symbol:string}[] = [];

    this.fillAffiliationsData(authors,collaborators,affiliationsFound,authorsAndSymbols)
    let pmNode = this.getPmNode(authorsAndSymbols,affiliationsFound);

    let view = this.headEditorContainer.editorView;
    let endOfOldDoc = view.state.doc.content.size;
    view.dispatch(view.state.tr.replaceWith(0,endOfOldDoc,pmNode))
  }

  getPmNode(authorsAndSymbols,affiliationsFound){
    let coAuthors = authorsAndSymbols.filter(x=>x.collaborator.is_co_author == true);
    let ns = schema.nodes
    let doc = ns.doc.create({},[
      ns.paragraph.create({},authorsAndSymbols.reduce((prev,curr,index)=>{
        let userNodes = []
        userNodes.push(schema.text(curr.collaborator.name))
        curr.affiliationSymbols.length>0?userNodes.push(schema.text(curr.affiliationSymbols.join(','),[schema.mark('superscript')])):undefined
        if(index>0){
          prev.push(schema.text(', '),...userNodes)
        }else{
          prev.push(...userNodes)
        }
        return prev
      },[])),
      ...affiliationsFound.reduce((prev,curr)=>{
        let par = ns.paragraph.create({},schema.text(curr.displayTxt));
        prev.push(par)
        return prev
      },[]),
      ns.inline_block_container.create({},[
        ns.form_field.create({},[
          ns.paragraph.create({},[
            schema.text('Corresponding author'+(coAuthors.length>1?'s':'')+': '),...coAuthors.reduce((prev,curr,index)=>{
              let nameText = schema.text(curr.collaborator.name+' ');
              let email = schema.text("("+curr.collaborator.email+")",[schema.mark('link',{href:'mailto: '+curr.collaborator.email})]);
              if(index>0){
                prev.push(schema.text(', '),nameText,email)
              }else{
                prev.push(nameText,email)
              }
              return prev
            },[])
          ]),
          ns.paragraph.create({},[
            schema.text('© '),...authorsAndSymbols.reduce((prev,curr,index)=>{
              let nameText = schema.text(curr.collaborator.name);
              if(index>0){
                prev.push(schema.text(', '),nameText)
              }else{
                prev.push(nameText)
              }
              return prev
            },[])
          ]),
          ns.paragraph.create({},[
            schema.text('Citation:')
          ])
        ]),
        ns.form_field.create({},[
          ns.paragraph.create({},[
            ns.image.create({src:'./assets/img/open_access_icon_colour.svg'})
          ]),
        ])
      ])
    ])
    return doc
  }

  renderHeadEditor = (htmlEl:any, versionData: any)=>{
    this.headEditorContainer = this.serviceShare.ProsemirrorEditorsService.renderDocumentHeadEditor(htmlEl);
    if(this.versionData) {
      this.headEditorContainer.editorView.dispatch(this.headEditorContainer.editorView.state.tr.setMeta(ySyncPluginKey, {...this.versionData, userData: this.serviceShare.ProsemirrorEditorsService.userInfo}));
    }
    this.subscription.add(this.serviceShare.YdocService.collaboratorsSubject.subscribe((data) => {
      this.setCollaboratorsData(data)
    }));
    this.setCollaboratorsData(this.serviceShare.YdocService.collaborators.get('collaborators'))
  }

  setCollaboratorsData(collaboratorsData: any) {
    setTimeout(() => {
      if(collaboratorsData) {
        this.collaborators = collaboratorsData?.collaborators
        this.authorsList = this.serviceShare.YdocService.collaborators?.get('authorsList') || [];
        this.setNewInvitedCollaboratorData();
        this.renderArticleMetaNode();
      }
    }, 30)
  }

  renderArticleMetaNode(){
    this.renderMetaNodeInHeadEditor(this.collaborators,this.authorsList);
  }

  setNewInvitedCollaboratorData(): void {
    this.subscription.add(this.serviceShare.AuthService.currentUser$.subscribe((currentUser) => {
      const invitedUserWithNoID = this.collaborators.find((user: contributorData) => user.id == currentUser.email);
      const invidetAuthorWithNoID = this.authorsList.find((user: any) => user.authorId == currentUser.email);

      if(invitedUserWithNoID) {
        this.collaborators = this.collaborators.map((user: contributorData) => {
          if(user.id == currentUser.email) {
            user.id = currentUser.id;
            user.name = currentUser.name;
            return user;
          } 
          return user;
        })
        this.serviceShare.YdocService.collaborators.set('collaborators', { collaborators: this.collaborators });
      }

      if(invidetAuthorWithNoID) {
        this.authorsList = this.authorsList.map((user: any) => {
          if(user.authorId == currentUser?.email) {
            user.authorId = currentUser.id;
            return user;
          } 
          return user;
        })
        this.serviceShare.YdocService.collaborators.set('authorsList', this.authorsList);
      }
    }));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
