import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { ReplaySubject } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

import { ServiceShare } from '@app/editor/services/service-share.service';
import { APP_CONFIG, AppConfig } from '../services/app-config';

@Injectable({
  providedIn: 'root'
})
export class AnyProjectsGuard implements CanActivate {
  subject = new ReplaySubject<any>(1);

  timeout: NodeJS.Timeout;
  count = 0;

  constructor(
    private sharedService: ServiceShare,
    public router: Router,
    @Inject(APP_CONFIG) readonly config: AppConfig
  ){

  }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if(this.config.standalone == undefined || this.config.standalone) {
      let isLoaded = false;

      return new Promise<boolean>((resolve, reject) => {
        let articlesGetObservable = this.sharedService.ArticlesService?.getAllArticles({page:1,pageSize:7,sort:'-id'}).pipe(shareReplay());
        articlesGetObservable.subscribe((res: any) => {
          this.count = 0;
          isLoaded = true;
          if(res) {
            if(res.status == 404){
              this.router.navigate(['create']);
              resolve(false)
            }else{
              resolve(true);
            }
          } else {
            resolve(true);
          }
        }, (error) => {
          isLoaded = true;
          this.router.navigate(['create']);
          console.error(error);
          resolve(false)
        })
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          if(!isLoaded && this.count == 0) {
            this.count++;
            this.router.navigate(["dashboard"]);
          }
        }, 2500);
        this.sharedService.addResolverData('DasboardResolver',articlesGetObservable);
      })
    } else if (this.config.standalone === false) {
      this.router.navigate(['home']);
      return false;
    } else {
      this.router.navigate(['404']);
      return false;
    }
  }
}
