import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { UntypedFormGroup } from "@angular/forms";
import { Subject } from 'rxjs';

//@ts-ignore
import {updateYFragment} from '../../y-prosemirror-src/plugins/sync-plugin.js';
import {DOMParser as DOMParserPM} from 'prosemirror-model';

import {EditSectionService} from '../dialogs/edit-section-dialog/edit-section.service';
import {ProsemirrorEditorsService} from '../services/prosemirror-editors.service';
import {articleSection, editorData} from '../utils/interfaces/articleSection';
import {TreeService} from '../meta-data-tree/tree-service/tree.service';
import {FormBuilderService} from '../services/form-builder.service';
import {YdocService} from '../services/ydoc.service';
import {DetectFocusService} from '../utils/detectFocusPlugin/detect-focus.service';
import {HelperService} from "@app/editor/section/helpers/helper.service";
import { ServiceShare } from '../services/service-share.service';
import { filterFieldsValues } from '../utils/fieldsMenusAndScemasFns';
import { schema } from '../utils/Schema';
import { AppConfig, APP_CONFIG } from '@app/core/services/app-config';
import { ySyncPluginKey } from '../../y-prosemirror-src/y-prosemirror.js';
import { TextSelection } from 'prosemirror-state';
import { EditorView } from 'prosemirror-view';

@Component({
  selector: 'app-section',
  templateUrl: './section.component.html',
  styleUrls: ['./section.component.scss'],
})
export class SectionComponent implements AfterViewInit {

  renderForm = false;

  hidehtml = true;
  hidejson = true;
  hideDefsjson = true;
  error = false;
  errorMessage = '';
  newValue?: { contentData: editorData, sectionData: articleSection };
  value?: string;
  codemirrorHTMLEditor?: EditorView
  codemirrorJsonEditor?: EditorView
  codemirrorMenusAndSchemasDefsEditor?: EditorView
  editorData?: editorData;
  FormStructure: any
  sectionTreeTitleValue = '';

  childrenTreeCopy?: articleSection[]
  complexSection = false;
  complexSectionDeletedChildren: articleSection[] = []
  complexSectionAddedChildren: articleSection[] = []

  @Input() component!: any;
  @Input() section!: articleSection;
  @Output() sectionChange = new EventEmitter<articleSection>();
  @Input() editOnAddFromParent?: true;
  @Input() sectionContent: any = undefined;
  @Input() versionData: any;

  _sectionForm!: UntypedFormGroup;
  sectionFormClone!: UntypedFormGroup;

  @Input() set sectionForm(val) {
    if(this.section.title.name == "Material") {
      this._sectionForm = new UntypedFormGroup({});
    } else {
      this._sectionForm = val;
      this.sectionFormClone = this.formBuilderService.cloneAbstractControl(this._sectionForm);
    }
  }

  get sectionForm() {
    return this._sectionForm;
  }

  @ViewChild('ProsemirrorEditor', {read: ElementRef}) ProsemirrorEditor?: ElementRef;

  triggerCustomSecSubmit = new Subject()

  constructor(
    private editSectionService: EditSectionService,
    private prosemirrorEditorsService: ProsemirrorEditorsService,
    private treeService: TreeService,
    private ydocService: YdocService,
    private formBuilderService: FormBuilderService,
    public detectFocusService: DetectFocusService,
    public helperService: HelperService,
    private serviceShare:ServiceShare,
    @Inject(APP_CONFIG) readonly config: AppConfig
    ) { }

  addCustomSectionData(section:articleSection,data:any){
    let customPropsObj = this.ydocService.customSectionProps?.get('customPropsObj');
    customPropsObj[section.sectionID] = data;
    this.ydocService.customSectionProps?.set('customPropsObj',customPropsObj);
  }


  onSubmit = async (submision?: any) => {
    try {
      if(this.section.title.name == "[MM] Materials" || this.section.title.name == "Material" || this.section.title.name == "[MM] Taxon treatments") {
        this.editSectionService.editChangeSubject.next(submision);
        return;
      }
      if(submision.data.sectionTreeTitle) {
        const matched = /<p\b[^>]*>(.*?)<\/p>/.exec(submision.data.sectionTreeTitle);
      
        if(matched) {
          submision.data.sectionTreeTitle = matched[1];
        } 
      }

      let prosemirrorNewNodeContent = this.section.prosemirrorHTMLNodesTempl;
      
      if(!prosemirrorNewNodeContent) {
        const match = /<ng-template #sectionTemplate>([\s\S]+?(?=<\/ng-template>))<\/ng-template>/gm.exec(this.section.originalSectionTemplate.template);
        if(match) {
          prosemirrorNewNodeContent = match[1];
        } else {
          prosemirrorNewNodeContent = this.section.originalSectionTemplate.template;
        }
      }
      
      filterFieldsValues(this.sectionContent, submision, this.serviceShare, this.section.sectionID, false, prosemirrorNewNodeContent, false);
      this.ydocService.sectionFormGroupsStructures!.set(this.section.sectionID, {
        data: submision.data,
        updatedFrom: this.ydocService.ydoc?.guid
      })

      if (
        this.section.title.name == 'Taxon' ||
        this.section.title.name == '[AM] Funder' ||
        this.serviceShare.DataPaperService.dataPaperSpecificSections.includes(this.section.title.name)
      ) {
        this.addCustomSectionData(this.section, submision.data);
      }
      
      const copySchema = this.formBuilderService.populateDefaultValues(submision.data, this.section.formIOSchema, this.section.sectionID,this.section, this.sectionForm);

      Object.keys(this.sectionForm.controls).forEach((key) => {
        this.sectionForm.removeControl(key);
      })
      const editGridComponentKeys = ['externalLinks', 'taxonomicCoverage', 'temporalCoverage', 'columns'];
      if(editGridComponentKeys.some(property => submision.data[property])) {
        copySchema.components.forEach((component: any, index: number) => {
          this.section.formIOSchema.components[index].defaultValue = component.defaultValue;
        });
        this.formBuilderService.buildFormGroupFromSchema(this.sectionForm, this.section.formIOSchema, this.section);
      } else {
        if(this.section.type == "complex" && copySchema.components) {
          this.treeService.optionalRequiredWithSubsection(copySchema, this.treeService.findNodeById(this.section.sectionID));
        }
        this.formBuilderService.buildFormGroupFromSchema(this.sectionForm, copySchema, this.section);
        this.treeService.setTitleListener(this.section);
      }
      this.sectionForm.patchValue(submision.data);
      this.sectionForm.updateValueAndValidity()

      let interpolated: any
      this.error = false;
      this.errorMessage = '';

      let { hTag } = this.treeService.getNodeLevel(this.section)
      if (prosemirrorNewNodeContent.indexOf(`<ng-template #${this.section.title.name.replace(/[\W_]+/g,'')}`) > -1) {
          interpolated = await this.prosemirrorEditorsService.interpolateTemplate(prosemirrorNewNodeContent!, submision.data, this.sectionForm, this.section.title.name.replace(/[\W_]+/g,''),{hTag});
      } else {
        interpolated = await this.prosemirrorEditorsService.interpolateTemplate(prosemirrorNewNodeContent!,submision.data, this.sectionForm, null, {hTag, linksPrefix: copySchema?.linksPrefix});
      }

      // if(submision.data.sectionTreeTitle && submision.data.sectionTreeTitle.length > 0){
      //   this.treeService.saveNewTitleChange(this.section, submision.data.sectionTreeTitle);
      // }

      submision.compiledHtml = interpolated;
      if(this.section.formIOSchema.optional) {
        this.editSectionService.editChangeSubject.next(submision);
        return;
      }
      
      this.treeService.updateNodeProsemirrorHtml(prosemirrorNewNodeContent, this.section.sectionID);
      this.editSectionService.editChangeSubject.next(submision);
      setTimeout(() => {
        const editor = this.prosemirrorEditorsService.editorContainers[this.section.sectionID];
        if(editor) {
          editor.editorView.dispatch(editor.editorView.state.tr.setMeta('emptyTR', true).setMeta('addToLastHistoryGroup', true));
        }
      }, 200);
    } catch (err: any) {
      this.error = true;
      this.errorMessage += 'An error occurred while interpolating the template.\n';
      this.errorMessage += err.message;
      console.error(new Error('An error occurred while interpolating the template.'));
      console.error(err.message);
      return;
    }
  }

  async initialRender() {
    if (this.treeService.sectionFormGroups[this.section.sectionID]) {
      this.sectionForm = this.treeService.sectionFormGroups[this.section.sectionID];
    } else {
      this.treeService.sectionFormGroups[this.section.sectionID] = new UntypedFormGroup({});
      this.sectionForm = this.treeService.sectionFormGroups[this.section.sectionID];
      this.formBuilderService.buildFormGroupFromSchema(this.sectionForm, this.section.formIOSchema, this.section);
      this.treeService.setTitleListener(this.section);
    }
    if(this.section.formIOSchema.optional) {
      this.prosemirrorEditorsService.renderEditorInWithId(this.ProsemirrorEditor?.nativeElement, this.section.sectionID, this.section, this.sectionContent?.components);
      return;
    }
    let submision: any = {};
    let interpolated: any;
    let prosemirrorNewNodeContent: any;
    this.error = false;
    this.errorMessage = '';
    
    prosemirrorNewNodeContent = this.section.prosemirrorHTMLNodesTempl;
    const root = this.helperService.filter(this.treeService.articleSectionsStructure, this.section.sectionID);
    let {nodeLevel, hTag} = this.treeService.getNodeLevel(this.section)
    if (root && this.ydocService.getSectionByID(root.sectionID).prosemirrorHTMLNodesTempl.indexOf(`<ng-template #${this.section.title.name.replace(/[\W_]+/g,'')}`) > -1) {
      prosemirrorNewNodeContent = root.prosemirrorHTMLNodesTempl;
      interpolated = await this.prosemirrorEditorsService.interpolateTemplate(prosemirrorNewNodeContent!, this.section.defaultFormIOValues, this.sectionForm, this.section.title.name.replace(/[\W_]+/g,''),{hTag});
    } else {
      interpolated = await this.prosemirrorEditorsService.interpolateTemplate(prosemirrorNewNodeContent!, {}, this.sectionForm, null,{hTag});
    }

    submision.compiledHtml = interpolated;
    this.prosemirrorEditorsService.trackChangesMeta.trackTransactions = false
    this.prosemirrorEditorsService.OnOffTrackingChangesShowTrackingSubject.next(
      this.prosemirrorEditorsService.trackChangesMeta
    )
    let xmlFragment = this.ydocService.ydoc.getXmlFragment(this.section.sectionID);
    let templDiv = document.createElement('div');
    templDiv.innerHTML = submision.compiledHtml
    let editorSchema = schema
    let node1 = DOMParserPM.fromSchema(editorSchema).parse(templDiv.firstChild!);

    updateYFragment(xmlFragment.doc, xmlFragment, node1, new Map());
    const editor = this.prosemirrorEditorsService.renderEditorInWithId(this.ProsemirrorEditor?.nativeElement, this.section.sectionID, this.section, this.sectionContent?.components);
    this.setInitialSelection(editor.editorView);
  }

  ngAfterViewInit(): void {
    if(this.section.title.name == "Material" && this.section.mode == "editMode" && !this.versionData) {
      return;
    }
    
    if(!this.sectionForm && this.treeService.sectionFormGroups[this.section.sectionID] && !this.versionData){
      this.section.title.label = this.treeService.findNodeById(this.section.sectionID)?.label || "";
      this.sectionContent = this.formBuilderService.populateDefaultValues(this.treeService.sectionFormGroups[this.section.sectionID].getRawValue(), this.section.formIOSchema, this.section.sectionID,this.section, this.sectionForm);
    }
    if (this.section.mode == 'documentMode' && this.section.active) {
      if(this.section.type == 'complex' && !this.versionData) {
        if(this.section.type == "complex" && this.sectionContent?.components) {
          this.treeService.optionalRequiredWithSubsection(this.sectionContent, this.treeService.findNodeById(this.section.sectionID));
        }
        let nodeForm = new UntypedFormGroup({});
        this.formBuilderService.buildFormGroupFromSchema(nodeForm, this.sectionContent, this.section);
        this.treeService.sectionFormGroups[this.section.sectionID] = nodeForm;
        this.treeService.setTitleListener(this.section);
        const editor = this.prosemirrorEditorsService.editorContainers[this.section.sectionID];
        if(editor) {
          editor.editorView.dispatch(editor.editorView.state.tr.setMeta('emptyTR', true).setMeta('addToLastHistoryGroup', true));
        }
      }
      if (this.section.initialRender == this.ydocService.ydoc.guid) {
        this.section.initialRender = undefined;
        this.initialRender();
        return;
      } else {
        this.section.initialRender = undefined;
        try {
          if(this.versionData) {
            const editor = this.prosemirrorEditorsService.renderEditorInWithId(this.ProsemirrorEditor?.nativeElement, this.section.sectionID, this.section,this.section.formIOSchema.components, this.versionData);            
            setTimeout(() => {
              editor.editorView.dispatch(editor.editorView.state.tr.setMeta(ySyncPluginKey, { ...this.versionData }));
            }, 100);
            if(Object.keys(this.prosemirrorEditorsService.editorContainers).length - 3 == Object.keys(this.prosemirrorEditorsService.editorContainers).indexOf(editor.editorID)) {
              this.prosemirrorEditorsService.stopSpinner();
            }
          } else {
            const editor = this.prosemirrorEditorsService.renderEditorInWithId(this.ProsemirrorEditor?.nativeElement, this.section.sectionID, this.section, this.sectionContent?.components);
            this.setInitialSelection(editor.editorView);
          }
        } catch (e) {
          console.error(e);
        }
      }
    }
  }

  setInitialSelection(view: EditorView) {
    //@ts-ignore
    if(!this.prosemirrorEditorsService.renderedSections.includes(view.sectionID)) {
      //@ts-ignore
      this.prosemirrorEditorsService.renderedSections.push(view.sectionID);
      setTimeout(() => {
        const pos = view.state.selection.to - 1;
        if (pos < 0) return; // section doesn't have content
        let newSel = TextSelection.create(view.state.doc, pos);
        view.dispatch(view.state.tr.setSelection(newSel));
      }, 200);
    }
  }
}
