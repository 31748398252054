import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { ArticleSectionsService } from '@app/core/services/article-sections.service';
import { AuthService } from '@app/core/services/auth.service';
import { ArticleDataViewComponent } from '../dialogs/article-data-view/article-data-view.component';
import { ChooseSectionComponent } from '../dialogs/choose-section/choose-section.component';
import { FiguresDialogComponent } from '../dialogs/figures-dialog/figures-dialog.component';
import { TreeService } from '../meta-data-tree/tree-service/tree.service';
import { ServiceShare } from '../services/service-share.service';
import { YdocService } from '../services/ydoc.service';
import { ContributorsApiService } from '@app/core/services/comments/contributors-api.service';
import { TestingComponent } from '../dialogs/testing/testing.component';
import { EnforcerService } from '@app/casbin/services/enforcer.service';
import { CitableTablesDialogComponent } from '../dialogs/citable-tables-dialog/citable-tables-dialog.component';
import { SupplementaryFilesDialogComponent } from '../dialogs/supplementary-files/supplementary-files.component';
import { EndNotesDialogComponent } from '../dialogs/end-notes/end-notes.component';
import { RefsInArticleDialogComponent } from '../dialogs/refs-in-article-dialog/refs-in-article-dialog.component';
import { sectionChooseData, willBeMoreThan4Levels } from '../utils/articleBasicStructure';
import { TextSelection } from 'prosemirror-state';
import { EditorView } from 'prosemirror-view';
import { RefsAddNewInArticleDialogComponent } from '../dialogs/refs-add-new-in-article-dialog/refs-add-new-in-article-dialog.component';
import { AddSupplementaryFileComponent } from '../dialogs/supplementary-files/add-supplementary-file/add-supplementary-file.component';
import { AddFigureDialogV2Component } from '../dialogs/figures-dialog/add-figure-dialog-v2/add-figure-dialog-v2.component';
import { AddTableDialogComponent } from '../dialogs/citable-tables-dialog/add-table-dialog/add-table-dialog.component';
import { AddEndNoteComponent } from '../dialogs/end-notes/add-end-note/add-end-note.component';
import { APP_CONFIG, AppConfig } from '@app/core/services/app-config';

@Component({
  selector: 'app-article-metadata',
  templateUrl: './article-metadata.component.html',
  styleUrls: ['./article-metadata.component.scss']
})
export class ArticleMetadataComponent implements AfterViewInit {

  sectionTemplates: any
  previewMode
  showAddFigures: boolean
  showAddReferences: boolean
  showAddTable: boolean
  showAddSupplementaryFiles: boolean
  showAddEndNotes: boolean

  buttons = [
    "References",
    "Suppl. Files",
    "Figures",
    "Tables",
    "Endnotes"
  ];
  buttonsData: any;

  constructor(
    public dialog: MatDialog,
    private sectionsService: ArticleSectionsService,
    private ydocService: YdocService,
    public enforcer: EnforcerService,
    private serviceShare: ServiceShare,
    private treeService: TreeService,
    private contributorsApiService: ContributorsApiService,
    private authService: AuthService,
    @Inject(APP_CONFIG) public config: AppConfig
    ) {
    this.previewMode = serviceShare.ProsemirrorEditorsService?.previewArticleMode!
    this.showAddFigures = this.ydocService.hasFigures
    this.showAddReferences = this.ydocService.hasReferences
    this.showAddTable = this.ydocService.hasTable
    this.showAddSupplementaryFiles = this.ydocService.hasSupplementaryMaterials
    this.showAddEndNotes = this.ydocService.hasFootnotes
    this.buttonsData = {
      "References": {
        condition: this.showAddReferences,
        modal: RefsInArticleDialogComponent,
        addModal: RefsAddNewInArticleDialogComponent,
        panelClass: ['editor-dialog-container', 'refs-add-new-in-article-dialog'],
        searchedElement: "heading"
      },
      "Suppl. Files": {
        condition: this.showAddSupplementaryFiles,
        modal: SupplementaryFilesDialogComponent,
        addModal: AddSupplementaryFileComponent,
        searchedElement: "supplementary_files_nodes_container",
        numbers: this.serviceShare.YdocService.supplementaryFilesMap,
        numbersName: "supplementaryFilesNumbers",
      },
      "Figures": {
        condition: this.showAddFigures,
        modal: FiguresDialogComponent,
        addModal: AddFigureDialogV2Component,
        searchedElement: "figures_nodes_container",
        numbers: this.serviceShare.YdocService.figuresMap,
        numbersName: "ArticleFiguresNumbers"
      },
      "Tables": {
        condition: this.showAddTable,
        modal: CitableTablesDialogComponent,
        addModal: AddTableDialogComponent,
        searchedElement: "tables_nodes_container",
        numbers: this.serviceShare.YdocService.tablesMap,
        numbersName: "ArticleTablesNumbers"
      },
      "Endnotes": {
        condition: this.showAddEndNotes,
        modal: EndNotesDialogComponent,
        addModal: AddEndNoteComponent,
        searchedElement: "end_notes_nodes_container",
        numbers: this.serviceShare.YdocService.endNotesMap,
        numbersName: "endNotesNumbers"
      }
    }
  }
  ngAfterViewInit(): void {
    this.buttonsData["References"].editor = this.serviceShare.ProsemirrorEditorsService.editorContainers["endEditor"]?.editorView;
    this.buttonsData["References"].isRendered = !this.ydocService.doNotRenderEndEditor;
    this.buttonsData["Suppl. Files"].editor = this.serviceShare.ProsemirrorEditorsService.editorContainers["supplementaryFilesView"]?.editorView;
    this.buttonsData["Suppl. Files"].isRendered = this.ydocService.hasSupplementaryMaterials;
    this.buttonsData["Figures"].editor = this.serviceShare.ProsemirrorEditorsService.editorContainers["endEditor"]?.editorView;
    this.buttonsData["Figures"].isRendered = !this.ydocService.doNotRenderEndEditor;
    this.buttonsData["Tables"].editor = this.serviceShare.ProsemirrorEditorsService.editorContainers["endEditor"]?.editorView;
    this.buttonsData["Tables"].isRendered = !this.ydocService.doNotRenderEndEditor;
    this.buttonsData["Endnotes"].editor = this.serviceShare.ProsemirrorEditorsService.editorContainers["endNotesEditor"]?.editorView;
    this.buttonsData["Endnotes"].isRendered = this.ydocService.hasFootnotes;
  }

  openTestingDialog(){
    this.dialog.open(TestingComponent, {
      //width: '100%',
      //height: '90%',
      data: {},
      disableClose: false
    });
  }

  refreshToken() {
    this.authService.refreshToken().subscribe((res) => {
    })
  }

  editBtnHandler (modal: any) {
    this.dialog.open(modal, {
      data: {},
      disableClose: false
    });
  }

  addCitableElement (selected: string) {
    const element = this.buttonsData[selected];

    let dialogRef: MatDialogRef<any, any>;
    let numbers: string[];
    
    if(selected == "Suppl. Files" || selected == "Figures" || selected == "Tables" || selected == "Endnotes") {
      numbers = JSON.parse(JSON.stringify(element.numbers.get(element.numbersName)));
      dialogRef = this.dialog.open(element.addModal, {
        panelClass: element.panelClass || [],
        data: { index: numbers.length },
        disableClose: false
      })
    } else {
      dialogRef = this.dialog.open(element.addModal, {
        panelClass: element.panelClass || [],
        data: { },
        disableClose: false
      })
    } 

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        if(result.supplementaryFile) {
          numbers = JSON.parse(JSON.stringify(element.numbers.get(element.numbersName)));
          const supplementaryFiles = JSON.parse(JSON.stringify(this.serviceShare.YdocService.supplementaryFilesMap.get('supplementaryFiles')));
          numbers.push(result.supplementaryFile.supplementary_file_ID);
          supplementaryFiles![result.supplementaryFile.supplementary_file_ID] = result.supplementaryFile;
          this.serviceShare.CitableElementsService.writeElementDataGlobal(supplementaryFiles, numbers, 'supplementary_file_citation');
        } else if (result.endNote) {
          numbers = JSON.parse(JSON.stringify(element.numbers.get(element.numbersName)));
          const endNotes = JSON.parse(JSON.stringify(this.serviceShare.YdocService.endNotesMap.get('endNotes')));
          numbers.push(result.endNote.end_note_ID);
          endNotes[result.endNote.end_note_ID] = result.endNote;
          this.serviceShare.CitableElementsService.writeElementDataGlobal(endNotes, numbers, 'end_note_citation');
        } else if (result.table) {
          numbers = JSON.parse(JSON.stringify(element.numbers.get(element.numbersName)));
          const hasTables = numbers.length > 0;
          const tables = JSON.parse(JSON.stringify(this.serviceShare.YdocService.tablesMap.get('ArticleTables')));
          numbers.push(result.table.tableID);
          tables![result.table.tableID] = result.table;
          this.serviceShare.CitableElementsService.writeElementDataGlobal(tables, numbers,'table_citation');
          if(!hasTables) this.serviceShare.TreeService.metadatachangeMap.set('change', { guid: this.ydocService.ydoc.guid });
        } else if (result.figure ) {
          numbers = JSON.parse(JSON.stringify(element.numbers.get(element.numbersName)));
          const figures = JSON.parse(JSON.stringify(this.serviceShare.YdocService.figuresMap.get('ArticleFigures')));
          numbers.push(result.figure.figureID);
          figures[result.figure.figureID] = result.figure;
          this.serviceShare.CitableElementsService.writeElementDataGlobal(figures, numbers, 'citation');
        } else if (result && result instanceof Array) {
          result.forEach((refInstance) => {
            const refId = refInstance.ref.ref.id;
            const references = JSON.parse(JSON.stringify(this.serviceShare.YdocService.referenceCitationsMap.get("refsAddedToArticle")));
            references[refId] = refInstance.ref;
            const newRefs = this.serviceShare.CslService.sortCitations(references);
            this.serviceShare.YdocService.referenceCitationsMap.set("refsAddedToArticle", newRefs);
          })
          setTimeout(()=>{
            this.serviceShare.EditorsRefsManagerService.updateRefsInEndEditorAndTheirCitations();
          },20)
        }
      }
    })
  }

  showButton(btnsContainer: HTMLDivElement, mouseOn: boolean) {
    if(mouseOn) {
      btnsContainer.style.display = "block";
    } else {
      btnsContainer.style.display = "none";
    }
  }

  scrollTo(btnName: string) {
    const editor = this.buttonsData[btnName].editor as EditorView;
    if(editor && this.buttonsData[btnName].isRendered) {
      const { doc } = editor.state;
      //@ts-ignore
        const nodeSize = doc.content?.content?.[1]?.nodeSize;
        if(nodeSize) {
          editor.focus();
          if(doc.childCount > 1) {
            editor.dispatch(editor.state.tr.scrollIntoView().setSelection(TextSelection.create(doc, nodeSize + 1)));
          } else {
            editor.dispatch(editor.state.tr.scrollIntoView().setSelection(TextSelection.create(doc, nodeSize - 2)));
          }
        }
    }
  }

  preventAddToHistory(){
    this.serviceShare.ProsemirrorEditorsService.ySyncPluginKeyObj.origin? this.serviceShare.ProsemirrorEditorsService.ySyncPluginKeyObj.origin = null:this.serviceShare.ProsemirrorEditorsService.ySyncPluginKeyObj.origin = this.serviceShare.ProsemirrorEditorsService.ySyncKey
  }

  addNewSectionToArticle() {
    let articleSections = this.ydocService.articleData.layout.template.sections.filter((data: any) => {
      let secIsNotAtMax = this.serviceShare.TreeService.checkIfNodeIsAtMaxInParentListWithBESection(data);
      return secIsNotAtMax;
    })
    this.sectionTemplates = articleSections;
    let mainSection:sectionChooseData[]=[]
    this.sectionTemplates.forEach((sec)=>{
      mainSection.push({
        id: sec.id,
        name: (sec.settings && sec.settings.label && sec.settings.label.length>0)?sec.settings.label:sec.name,
        secname:sec.name,
        version: sec.version,
        version_date: sec.version_date,
        source:'template',
        pivot_id : sec.pivot_id,
        template:sec
      })
    })
    const dialogRef = this.dialog.open(ChooseSectionComponent, {
      width: '563px',
      panelClass: 'choose-namuscript-dialog',
      data: { templates: mainSection,sectionlevel:0 }
    });
    dialogRef.afterClosed().subscribe((result:sectionChooseData) => {
      if(result){
        if(!willBeMoreThan4Levels(0,result.template)){
          this.treeService.addNodeAtPlaceChange('parentList', result.template, 'end');
        }else{
          this.serviceShare.openSnackBar('Adding this subsection will exceed the maximum levels of the tree.','Close',()=>{},4000);
        }
      }
    });
  }

  showArticleData() {
    console.log(this.ydocService.ydoc.toJSON());
    console.log(this.treeService.sectionFormGroups);
    
    // const dialogRef = this.dialog.open(ArticleDataViewComponent, {
    //   width: '90%',
    //   height: '90%',
    //   panelClass: 'show-article-data',
    //   data: {
    //     articleSectionsStructure: JSON.parse(JSON.stringify(this.treeService.articleSectionsStructure)),
    //     sectionFormGroups: this.treeService.sectionFormGroups,
    //     articleCitatsObj: JSON.parse(JSON.stringify(this.ydocService.figuresMap!.get('articleCitatsObj'))),
    //     ArticleFigures: JSON.parse(JSON.stringify(this.ydocService.figuresMap!.get('ArticleFigures')))
    //   }
    // });
  }
}
